<script setup>
import { ref } from 'vue';
import {TrashIcon} from "@heroicons/vue/20/solid";

const mm = [
  {
    name: 'BERROU Bernard',
    chevalier: '2016A',
    officier: '-',
    commandeur: '-',
    autres: '-',
  },
  {
    name: 'BERROU Christian',
    chevalier: '2009C',
    officier: '2011C',
    commandeur: '1968A',
    autres: 'CLH CNM',
  },
  {
    name: 'BERROU Bernard',
    chevalier: '2016A',
    officier: '-',
    commandeur: '-',
    autres: '-',
  }
]

const mh = [
  {
    name: 'BERROU Joseph-Marie',
    annee: '1935',
    quartier: 'Lorient',
    fonction: 'Matelot à Ploemeur',
  },
  {
    name: 'LE GAC Pierre',
    annee: '1936',
    quartier: 'Quimper',
    fonction: 'Chauffeur à Concarneau',
  },
  {
    name: 'KERMOAL Yves',
    annee: '1937',
    quartier: 'Brest',
    fonction: 'Pêcheur à Douarnenez',
  },
  {
    name: 'LE GALL Jean-Louis',
    annee: '1938',
    quartier: 'Vannes',
    fonction: 'Capitaine à Belle-Île',
  },
  {
    name: 'KERJEAN Alain',
    annee: '1939',
    quartier: 'Saint-Malo',
    fonction: 'Officier à Cancale',
  },
  {
    name: 'PRIGENT Hervé',
    annee: '1940',
    quartier: 'Lorient',
    fonction: 'Second à Groix',
  },
  {
    name: 'LE ROUX François',
    annee: '1941',
    quartier: 'Roscoff',
    fonction: 'Navigateur à Carantec',
  }
];

const settings = [
  { name: 'MH', description: 'LES DÉCORÉS MÉDAILLE D\'HONNEUR', selected: true },
  { name: 'MM', description: 'LES DÉCORÉS MÉRITE MARITIME', selected: false },
]

const selectedSetting = ref('MH');

const emit = defineEmits(['edit-item']);
const editItem = (type) => {
  emit('edit-item', type);
};
</script>

<template>
  <div class="bg-gray-900 p-6 rounded-xl shadow mt-20 border-gray-700 border">
    <div class="col-span-full mb-6">
      <div>
        <input type="text" name="search" id="search" autocomplete="search" placeholder="Rechercher un nom" class="px-4 py-4 block w-full rounded-md border-0 bg-white/5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm/6" />
      </div>
    </div>
    <fieldset aria-label="Privacy setting" class="-space-y-px rounded-md bg-gray-800 mb-6">
      <label v-for="setting in settings" :key="setting.name" :value="setting.name" :aria-label="setting.name" :aria-description="setting.description" class="group flex cursor-pointer border border-gray-500 p-4 first:rounded-tl-md first:rounded-tr-md last:rounded-bl-md last:rounded-br-md focus:outline-none has-[:checked]:relative has-[:checked]:border-green-200 has-[:checked]:bg-gray-700">
        <input name="privacy-setting" v-model="selectedSetting"  :value="setting.name" type="radio" :checked="setting.selected" class="relative mt-0.5 size-4 shrink-0 appearance-none rounded-full border border-gray-300 bg-gray-700 before:absolute before:inset-1 before:rounded-full before:bg-green-500 checked:border-green-600 checked:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600 disabled:border-gray-300 disabled:bg-gray-100 disabled:before:bg-gray-400 forced-colors:appearance-auto forced-colors:before:hidden [&:not(:checked)]:before:hidden" />
        <span class="ml-3 flex flex-col">
        <span class="block text-sm font-medium text-gray-400 group-has-[:checked]:text-white">{{ setting.name }}</span>
        <span class="block text-sm text-gray-600 group-has-[:checked]:text-gray-300">{{ setting.description }}</span>
      </span>
      </label>
    </fieldset>
    <button type="submit" class="rounded-md mb-6 flex w-full justify-center bg-green-600 px-3 py-4 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600">Créer</button>

    <h1 class="text-gray-400 mb-6">Résultat</h1>

<!--    MM LIST-->
    <div v-if="selectedSetting === 'MM'"  class="mx-auto grid max-w-7xl grid-cols-1 gap-12 xl:grid-cols-1">
      <ul role="list" class="mx-auto w-full grid max-w-4xl grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-2 lg:mx-0 lg:max-w-none lg:gap-x-8 xl:col-span-2">
        <li v-for="mm in mm" :key="mm.name" class="border border-gray-500 p-2 rounded shadow bg-gray-700">
          <h3 class=" text-lg/8 font-semibold text-gray-200">{{ mm.name }}</h3>
          <div class="grid lg:grid-cols-4 grid-cols-3">
            <div class="flex flex-col">
              <h4 class="text-gray-300">Chevalier</h4>
              <p class="text-base/7 text-gray-200">{{ mm.chevalier }}</p>
            </div>
            <div class="flex flex-col">
              <h4 class="text-gray-300">Officier</h4>
              <p class="text-base/7 text-gray-200">{{ mm.officier }}</p>
            </div>
            <div class="flex flex-col">
              <h4 class="text-gray-300">Commandeur</h4>
              <p class="text-base/7 text-gray-200">{{ mm.commandeur }}</p>
            </div>
            <div class="flex flex-col">
              <h4 class="text-gray-300">Autres</h4>
              <p class="text-base/7 text-gray-200">{{ mm.autres }}</p>
            </div>
          </div>
          <div class="mt-6 flex items-center justify-end gap-x-4">
            <button type="submit" @click="editItem('MM')" class="rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600">Modifier</button>
            <button type="submit" class="rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"><TrashIcon class="w-5 h-5 shrink-0 text-gray-200 hover:text-red-400" aria-hidden="true"/></button>
          </div>
        </li>
      </ul>
    </div>

<!--    MH LIST-->
    <div v-if="selectedSetting === 'MH'"  class="mx-auto grid max-w-7xl grid-cols-1 gap-12 xl:grid-cols-1">
      <ul role="list" class="mx-auto w-full grid max-w-4xl grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-2 lg:mx-0 lg:max-w-none lg:gap-x-8 xl:col-span-2">
        <li v-for="mh in mh" :key="mh.name" class="border border-gray-500 p-2 rounded bg-gray-700 shadow">
          <h3 class=" text-lg/8 font-semibold text-gray-200">{{ mh.name }}</h3>
          <div class="grid sm:grid-cols-3 grid-cols-2">
            <div class="flex flex-col">
              <h4 class="text-gray-300">Année</h4>
              <p class="text-base/7 text-gray-200">{{ mh.annee }}</p>
            </div>
            <div class="flex flex-col">
              <h4 class="text-gray-300">Quartier</h4>
              <p class="text-base/7 text-gray-200">{{ mh.quartier }}</p>
            </div>
            <div class="flex flex-col">
              <h4 class="text-gray-300">Fonction</h4>
              <p class="text-base/7 text-gray-200">{{ mh.fonction }}</p>
            </div>
          </div>
          <div class="mt-6 flex items-center justify-end gap-x-4">
            <button type="submit" @click="editItem('MH')" class="rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600">Modifier</button>
            <button type="submit" class="rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"><TrashIcon class="w-5 h-5 shrink-0 text-gray-200 hover:text-red-400" aria-hidden="true"/></button>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>