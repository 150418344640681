<template>
  <div class="bg-white py-24">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <h2 class="max-w-2xl text-balance text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">Nous contacter</h2>
      <div class="mt-20 flow-root">
        <div class="isolate -mt-16 grid max-w-sm grid-cols-1 gap-y-16 divide-y divide-gray-100 sm:mx-auto lg:-mx-8 lg:mt-0 lg:max-w-none lg:grid-cols-2 lg:divide-x lg:divide-y-0 xl:-mx-4">
          <div v-for="tier in tiers" :key="tier.id" class="pt-16 lg:px-8 lg:pt-0 xl:px-8">
            <h3 :id="tier.id" class="text-3xl font-semibold text-gray-900">{{ tier.name }}</h3>
            <p class="mt-10 text-sm/6 font-semibold text-gray-900 min-h-[60px]">{{ tier.description }}</p>
            <a :href="tier.href" :aria-describedby="tier.id" class="mt-10 block rounded-md bg-[#072653] px-3 py-2 text-center text-sm/6 font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
              Consulter
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>

const tiers = [
  {
    name: 'Visiteur',
    id: 'visiteur',
    href: '#',
    description: 'Vous n\'êtes pas membre, mais vous souhaitez nous contacter ? C\'est ici !',
  },
  {
    name: 'Espace Membre',
    id: 'membre',
    href: '#',
    description: 'Déjà membre de notre fédération et besoin de nous contacter ? Nous sommes là pour vous aider !',
  },
]
</script>