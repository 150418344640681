<script setup>
import { TrashIcon } from "@heroicons/vue/20/solid";

const cat = [
  {
    id: '5',
    name: 'PRIX 2025 Première selection',
  },
  {
    id: '4',
    name: 'Prix 2025',
  },
  {
    id: '3',
    name: 'Prix 2024',
  },
  {
    id: '2',
    name: 'Remise des Prix 2023 à Pornic',
  },
  {
    id: '1',
    name: 'Saint-Gilles Croix de Vie délibération du Jury 2022 Maison des Ecrivains de la Mer',
  },
];

const item = [
  {
    id: '5',
    name: 'PRIX 2025 Première sélection',
    href: 'https://api.meritemaritime-fnmm.com/uploads/1730879447805.jpg',
  },
  {
    id: '4',
    name: 'PRIX 2025 Première sélection',
    href: 'https://api.meritemaritime-fnmm.com/uploads/1729750410396.jpg',
  },
  {
    id: '3',
    name: 'Prix ECUMES DE MER',
    href: 'https://api.meritemaritime-fnmm.com/uploads/1725834515245.png',
  },
  {
    id: '3',
    name: 'Prix JEAN LOREAU',
    href: 'https://api.meritemaritime-fnmm.com/uploads/1725834524477.png',
  },
  {
    id: '3',
    name: 'PREMIERE REUNION DE SELECTION POUR LE PRIX SEPTEMBRE 2024',
    href: 'https://api.meritemaritime-fnmm.com/uploads/1729750410396.jpg',
  },
  {
    id: '2',
    name: 'Prix ECUMES DE MER',
    href: 'https://api.meritemaritime-fnmm.com/uploads/1725834542011.png',
  },
  {
    id: '2',
    name: 'Prix JEAN LOREAU',
    href: 'https://api.meritemaritime-fnmm.com/uploads/1725834530333.png',
  },
  {
    id: '1',
    name: 'Prix ECUMES DE MER',
    href: 'https://api.meritemaritime-fnmm.com/uploads/1725834547947.png',
  },
  {
    id: '1',
    name: 'Prix JEAN LOREAU',
    href: 'https://api.meritemaritime-fnmm.com/uploads/1725834555026.png',
  },
];
</script>

<template>
  <div class="bg-gray-900 p-6 rounded-xl border-gray-700 border shadow mb-8">
    <h1 class="text-2xl text-gray-200 mb-6">Créer une catégorie</h1>
    <div class="col-span-full">
      <label for="title" class="block text-sm/6 font-medium text-white">Nom de la catégorie</label>
      <div class="mt-2">
        <input type="text" name="title" id="title" placeholder="-" class="mt-2 px-4 py-4 block w-full rounded-md border-0 bg-white/5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm/6"/>
      </div>
    </div>
    <div class="mt-6 flex items-center justify-end gap-x-6">
      <button type="submit" class="rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600">
        Créer
      </button>
    </div>
  </div>

  <div class="w-full flex flex-col">
    <ul role="list" class="mx-auto grid max-w-3xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-1 lg:mx-0 lg:grid-cols-1 lg:max-w-none lg:gap-x-8 xl:col-span-2">
      <li v-for="cat in cat" :key="cat.name" class="bg-gray-900 p-6 rounded-xl border-gray-700 border flex flex-col gap-6">
        <h1 class="text-gray-200 mb-4 text-2xl">
          {{ cat.name }}
        </h1>
        <div class="border-gray-700 border-b pb-6">
          <label for="" class="text-gray-200">Nom de la catégorie</label>
          <input type="text" name="cat" :value="cat.name" id="cat" placeholder="-" class="mt-2 px-4 py-4 block w-full rounded-md border-0 bg-white/5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm/6"/>
        </div>
        <div class="bg-gray-800 p-6 border-gray-700 border shadow rounded-xl">
          <div class="items-center gap-4 mt-6 grid grid-cols-1 lg:grid-cols-2">
            <div class="w-full flex flex-col">
              <label for="" class="text-gray-200">Titre</label>
              <input type="text" name="title" id="title" placeholder="-" class="mt-2 px-4 py-4 block w-full rounded-md border-0 bg-white/5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm/6"/>
            </div>
            <div class="w-full flex flex-col">
              <label for="" class="text-gray-200">URL</label>
              <input type="text" name="cat" id="cat" placeholder="-" class="mt-2 px-4 py-4 block w-full rounded-md border-0 bg-white/5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm/6"/>
            </div>
          </div>
          <div class="mt-4 flex items-center justify-end gap-x-6">
            <button type="submit" class="rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600">
              Ajouter
            </button>
          </div>
        </div>
        <div v-for="item in item.filter(i => i.id === cat.id)" :key="item.name" class="bg-gray-800 p-6 rounded-xl border border-gray-700 mt-6">
          <h1 class="text-gray-200 mb-4 text-xl"> {{ item.name }}</h1>
          <img :src="item.href" class="rounded-xl border-gray-700 border h-[160px]" />
          <div class="items-center gap-4 mt-6 grid grid-cols-1 lg:grid-cols-2">
            <div class="w-full flex flex-col">
              <label for="" class="text-gray-200">Titre</label>
              <input type="text" name="title" :value="item.name" id="title" placeholder="-" class="mt-2 px-4 py-4 block w-full rounded-md border-0 bg-white/5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm/6"/>
            </div>
            <div class="w-full flex flex-col">
              <label for="" class="text-gray-200">URL</label>
              <input type="text" name="cat" :value="item.href" id="cat" placeholder="-" class="mt-2 px-4 py-4 block w-full rounded-md border-0 bg-white/5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm/6"/>
            </div>
          </div>
          <div class="mt-6 flex items-center justify-end gap-x-4">
            <button type="submit" class="rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600">
              Enregistrer
            </button>
            <button type="submit" class="rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600">
              <TrashIcon class="w-5 h-5 shrink-0 text-gray-200 hover:text-red-400" aria-hidden="true"/>
            </button>
          </div>
        </div>
        <div class="justify-end flex">
          <button type="submit" class="rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600">
            <TrashIcon class="w-5 h-5 shrink-0 text-gray-200 hover:text-red-400" aria-hidden="true"/>
          </button>
        </div>
      </li>
    </ul>
    <div class="mt-4 flex items-center justify-end gap-x-6">
      <button type="submit" class="rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600">
        Enregistrer
      </button>
    </div>
  </div>
</template>
