<script setup>
import { ref, watch } from 'vue';

const props = defineProps({
  selectedType: {
    type: String,
    required: true,
  },
});

const currentType = ref(props.selectedType);

watch(() => props.selectedType, (newType) => {
  currentType.value = newType;
});
</script>

<template>
<!--  MM-->
  <div class="bg-gray-900 p-6 rounded-xl shadow mt-20 border-gray-700 border" v-if="currentType === 'MM'">
    <div>
      <label for="name" class="text-gray-200">Nom complet</label>
      <input type="text" name="name" id="name" placeholder="-" class="mt-2 px-4 py-4 block w-full rounded-md border-0 bg-white/5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm/6" />
    </div>
    <div class="bloc grid-cols-1 md:grid-cols-3 lg:grid-cols-4 grid gap-4 mt-4">
      <div>
        <label for="Chevalier" class="text-gray-200">Chevalier</label>
        <input type="text" name="Chevalier" id="Chevalier" placeholder="-" class="mt-2 px-4 py-4 block w-full rounded-md border-0 bg-white/5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm/6" />
      </div>
      <div>
        <label for="Officier" class="text-gray-200">Officier</label>
        <input type="text" name="Officier" id="Officier" placeholder="-" class="mt-2 px-4 py-4 block w-full rounded-md border-0 bg-white/5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm/6" />
      </div>
      <div>
        <label for="Commandeur" class="text-gray-200">Commandeur</label>
        <input type="text" name="Commandeur" id="Commandeur" placeholder="-" class="mt-2 px-4 py-4 block w-full rounded-md border-0 bg-white/5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm/6" />
      </div>
      <div>
        <label for="Autres" class="text-gray-200">Autres</label>
        <input type="text" name="Autres" id="Autres" placeholder="-" class="mt-2 px-4 py-4 block w-full rounded-md border-0 bg-white/5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm/6" />
      </div>
    </div>
    <div class="mt-6 flex items-center justify-end gap-x-4">
      <button type="submit" class="rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600">Annuler</button>
      <button type="submit" class="rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600">Enregistrer</button>
    </div>
  </div>

<!--  MH-->
  <div class="bg-gray-900 p-6 rounded-xl shadow mt-20 border-gray-700 border" v-if="currentType === 'MH'">
    <div>
      <label for="name" class="text-gray-200">Nom complet</label>
      <input type="text" name="name" id="name" placeholder="-" class="mt-2 px-4 py-4 block w-full rounded-md border-0 bg-white/5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm/6" />
    </div>
    <div class="bloc grid-cols-1 md:grid-cols-3 lg:grid-cols-3 grid gap-4 mt-4">
      <div>
        <label for="Année" class="text-gray-200">Année</label>
        <input type="text" name="Année" id="Année" placeholder="-" class="mt-2 px-4 py-4 block w-full rounded-md border-0 bg-white/5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm/6" />
      </div>
      <div>
        <label for="Quartier" class="text-gray-200">Quartier</label>
        <input type="text" name="Quartier" id="Quartier" placeholder="-" class="mt-2 px-4 py-4 block w-full rounded-md border-0 bg-white/5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm/6" />
      </div>
      <div>
        <label for="Fonction" class="text-gray-200">Fonction</label>
        <input type="text" name="Fonction" id="Fonction" placeholder="-" class="mt-2 px-4 py-4 block w-full rounded-md border-0 bg-white/5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm/6" />
      </div>
    </div>
    <div class="mt-6 flex items-center justify-end gap-x-4">
      <button type="submit" class="rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600">Annuler</button>
      <button type="submit" class="rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600">Enregistrer</button>
    </div>
  </div>

</template>