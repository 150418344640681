<script setup>
const president = [
  {
    name: 'Fabrice LOHER',
    role: 'Président',
    bio: 'Secrétaire d\'Etat à la Mer auprès du Premier Ministre chargé de la Mer.',
  },
  {
    name: 'L\'Amiral (2S) Bernard-Antoine MORIO DE L\'ISLE',
    role: 'Vice-Président',
    bio: 'Membre du Conseil de l\'Ordre de la Légion d\'Honneur.',
  },
]
const people = [
  {
    name: 'Terry OLSON',
    role: 'Conseiller d\'Etat',
    bio: '',
  },
  {
    name: 'Contre-amiral (2S) Jean-Michel MARTINET',
    role: 'Officier Général de la Marine Nationale',
    bio: '',
  },
  {
    name: 'Guillaume SELLIER',
    role: 'Administrateur Général hors classe des Affaires Maritimes',
    bio: '',
  },
  {
    name: 'Eric BANEL',
    role: 'Directeur Général des Affaires Maritimes de la pêche et de l\'aquaculture',
    bio: '',
  },
  {
    name: 'Christiane EZCUTARI',
    role: 'Présidente du Conseil Supérieur de la Marine Marchande',
    bio: '',
  },
  {
    name: 'Jean-François JOUFFRAY',
    role: 'Président du Conseil Supérieur des Gens de Mer',
    bio: '',
  },
  {
    name: 'Sophie PANONACLE',
    role: 'Présidente du Bureau du Conseil National de la Mer et des Littoraux.',
    bio: '',
  },
  {
    name: 'Olivier LE NEZET',
    role: 'Président du Comité National des Pêches Maritimes et Elevages Marins.',
    bio: '',
  },
  {
    name: 'Marie-Luce PENCHARD',
    role: 'Personnalité ultramarine qualifiée.',
    bio: '',
  },
]
</script>

<template>
  <div class="bg-gray-900 p-6 rounded-xl shadow border-gray-700 border">
    <div class="mx-auto max-w-2xl lg:mx-0 mb-4">
      <h2 class="text-pretty text-2xl font-semibold tracking-tight text-gray-200 sm:text-2xl">Présidents</h2>
    </div>
    <ul role="list" class="mx-auto grid max-w-2xl grid-cols-1 gap-x-6 gap-y-20 sm:grid-cols-1 lg:mx-0  lg:grid-cols-2 lg:max-w-none lg:gap-x-8 xl:col-span-2">
      <li v-for="president in president" :key="president.name" class="bg-gray-800 p-6 rounded-xl border-gray-700 border flex flex-col gap-6">
        <div>
          <label for="name" class="text-gray-200">Nom complet</label>
          <input type="text" name="name" :value= president.name  id="name" placeholder="-" class="mt-2 px-4 py-4 block w-full rounded-md border-0 bg-white/5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm/6" />
        </div>
        <div>
          <label for="role" class="text-gray-200">Rôle</label>
          <input type="text" name="role" :value= president.role  id="role" placeholder="-" class="mt-2 px-4 py-4 block w-full rounded-md border-0 bg-white/5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm/6" />
        </div>
        <div>
          <label for="bio" class="text-gray-200">Bio</label>
          <textarea type="text" name="bio" :value= president.bio  id="bio" placeholder="-" class="mt-2 max-h-[150px] min-h-20 px-4 py-4 block w-full rounded-md border-0 bg-white/5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm/6" />
        </div>
      </li>
    </ul>
    <div class="mt-6 flex items-center justify-end gap-x-6">
      <button type="submit" class="rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600">Enregistrer</button>
    </div>
  </div>
  <div class="bg-gray-900 p-6 rounded-xl shadow border-gray-700 border mt-10">
    <div class="mx-auto max-w-2xl lg:mx-0 mb-4">
      <h2 class="text-pretty text-2xl font-semibold tracking-tight text-gray-200 sm:text-2xl">Membres</h2>
    </div>
    <ul role="list" class="mx-auto grid max-w-2xl grid-cols-1 gap-x-6 gap-y-20 sm:grid-cols-1 lg:mx-0  lg:grid-cols-2 lg:max-w-none lg:gap-x-8 xl:col-span-2">
      <li v-for="people in people" :key="people.name" class="bg-gray-800 p-6 rounded-xl border-gray-700 border flex flex-col gap-6">
        <div>
          <label for="name" class="text-gray-200">Nom complet</label>
          <input type="text" name="name" :value= people.name  id="name" placeholder="-" class="mt-2 px-4 py-4 block w-full rounded-md border-0 bg-white/5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm/6" />
        </div>
        <div>
          <label for="role" class="text-gray-200">Rôle</label>
          <input type="text" name="role" :value= people.role  id="role" placeholder="-" class="mt-2 px-4 py-4 block w-full rounded-md border-0 bg-white/5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm/6" />
        </div>
        <div>
          <label for="bio" class="text-gray-200">Bio</label>
          <textarea type="text" name="bio" :value= people.bio  id="bio" placeholder="-" class="mt-2 max-h-[150px] min-h-20 px-4 py-4 block w-full rounded-md border-0 bg-white/5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm/6" />
        </div>
      </li>
    </ul>
    <div class="mt-6 flex items-center justify-end gap-x-6">
      <button type="submit" class="rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600">Enregistrer</button>
    </div>
  </div>
</template>
