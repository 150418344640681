<template>
  <div class="bg-white">
    <div class="mx-auto max-w-7xl pt-0 sm:px-6 sm:py-12 lg:px-8">
      <div class="relative overflow-hidden rounded-none sm:rounded-lg lg:rounded-lg h-[500px] sm:h-[500px] shadow">
        <div class="flex transition-transform duration-700 ease-in-out" :style="{ transform: `translateX(-${currentSlide * 100}%)` }">
          <a href="/" v-for="(image, index) in images" :key="index" class="w-[100%] flex-shrink-0 h-full">
            <img :src="image" alt="" class="w-full min-h-[500px] h-full object-cover object-center" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';

const images = [
  require('@/assets/Home/Carousel/home.jpg'),
  require('@/assets/Home/Carousel/home.jpg'),
  require('@/assets/Home/Carousel/home.jpg')
];

const currentSlide = ref(0);
let intervalId;

const startCarousel = () => {
  intervalId = setInterval(() => {
    currentSlide.value = (currentSlide.value + 1) % images.length;
  }, 5000);
};

onMounted(() => {
  startCarousel();
});

onUnmounted(() => {
  clearInterval(intervalId);
});
</script>
