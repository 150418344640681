<template>
  <form>
    <div class="space-y-12">
      <div class="pb-12">
        <h2 class="text-base/7 font-semibold text-white">Lecture</h2>
        <p class="mt-1 text-sm/6 text-gray-400">Créer ou mettre à jour un article.</p>
        <div class="justify-end flex w-full">
          <button class="px-4 py-2 bg-gray-900 rounded-xl hover:bg-gray-700"><TrashIcon class="w-5 h-5 shrink-0 text-gray-400 hover:text-red-400" aria-hidden="true" /></button>
        </div>
        <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div class="col-span-full">
            <label for="title" class="block text-sm/6 font-medium text-white">Titre</label>
            <div class="mt-2">
              <input type="text" name="title" id="title" placeholder="-" class="block w-full rounded-md border-0 bg-white/5  py-4 px-4 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm/6" />
            </div>
          </div>

          <div class="col-span-full">
            <label for="url" class="block text-sm/6 font-medium text-white">URL image</label>
            <div class="mt-2">
              <input type="text" name="url" id="url" placeholder="-" class="block w-full rounded-md border-0 bg-white/5  py-4 px-4  text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm/6" />
            </div>
          </div>

          <div class="col-span-full">
            <label for="about" class="block text-sm/6 font-medium text-white">Description</label>
            <div class="mt-2">
              <textarea id="about" name="about" rows="3" placeholder="-" class="block w-full rounded-md border-0 bg-white/5  py-4 px-4 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm/6" />
            </div>
          </div>

          <div class="col-span-full">
            <label for="file-upload" class="block text-sm font-medium text-gray-300">Fichier</label>
            <label for="file-upload" class="mt-2 flex cursor-pointer justify-center rounded-lg border border-dashed border-gray-500 px-6 py-10">
              <div class="text-center">
                <svg class="mx-auto size-12 text-gray-400" fill="none" viewBox="0 0 48 48" stroke="currentColor" aria-hidden="true">
                  <path d="M6 4h24l8 8v32H6V4z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  <line x1="12" y1="20" x2="32" y2="20" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></line>
                  <line x1="12" y1="26" x2="28" y2="26" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></line>
                  <line x1="12" y1="32" x2="24" y2="32" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></line>
                </svg>
                <div class="mt-4 flex text-sm text-gray-300">
                  <label for="file-upload" class="relative cursor-pointer rounded-md font-semibold text-white focus-within:outline-none focus-within:ring-2 focus-within:ring-green-600 focus-within:ring-offset-2 focus-within:ring-offset-gray-900 hover:text-green-500">
                    <span>Choisir un fichier</span>
                    <input id="file-upload" name="file-upload" type="file" class="sr-only" />
                  </label>
                  <p class="pl-1">ou glisser-déposer ici</p>
                </div>
                <p class="mt-1 text-xs text-gray-400">Formats acceptés : PDF jusqu'à 10MB</p>
              </div>
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-6">
      <dl class="divide-y divide-white/10">
        <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
          <dt class="text-sm/6 font-medium text-white">Tout les fichiers</dt>
          <dd class="mt-2 text-sm text-white sm:col-span-2 sm:mt-0">
            <ul role="list" class="divide-y divide-white/10 rounded-md border border-white/20">
              <li v-for="file in file" :key="file.title" class="flex items-center justify-between py-4 pl-4 pr-5 text-sm/6">
                <div class="flex w-0 flex-1 items-center">
                  <PaperClipIcon class="size-5 shrink-0 text-gray-400" aria-hidden="true" />
                  <div class="ml-4 flex min-w-0 flex-1 gap-2">
                    <span class="truncate font-medium">{{ file.title }}</span>
                    <span class="shrink-0 text-gray-400">{{ file.size }}</span>
                  </div>
                </div>
                <div class="ml-4 shrink-0 items-center flex gap-4">
                  <a :href="file.href" class="font-medium text-green-400 hover:text-green-300">Télécharger</a>
                  <button><TrashIcon class="w-5 h-5 shrink-0 text-gray-400 hover:text-red-400" aria-hidden="true" /></button>
                </div>
              </li>
            </ul>
          </dd>
        </div>
      </dl>
    </div>

    <div class="mt-6 flex items-center justify-end gap-x-6">
      <button type="button" class="text-sm/6 font-semibold text-white" @click="$emit('cancel')">Annuler</button>
      <button type="submit" class="rounded-md bg-green-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-500">Sauvegarder</button>
    </div>
  </form>
</template>

<script setup>
import { PhotoIcon, UserCircleIcon } from '@heroicons/vue/24/solid'
import {PaperClipIcon, TrashIcon} from "@heroicons/vue/20/solid";

const file = [
  {
    title: 'financial_report_q3.pdf',
    size: '2.9mb',
    href: '/documents/financial_report_q3.pdf',
  },
  {
    title: 'marketing_strategy_2024.pdf',
    size: '3.5mb',
    href: '/documents/marketing_strategy_2024.pdf',
  },
  {
    title: 'employee_handbook.pdf',
    size: '5.0mb',
    href: '/documents/employee_handbook.pdf',
  },
  {
    title: 'product_roadmap.pdf',
    size: '2.7mb',
    href: '/documents/product_roadmap.pdf',
  },
  {
    title: 'meeting_notes_october.pdf',
    size: '1.6mb',
    href: '/documents/meeting_notes_october.pdf',
  },
  {
    title: 'company_overview.pdf',
    size: '3.8mb',
    href: '/documents/company_overview.pdf',
  },
];
</script>