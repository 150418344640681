<template>
  <div class="mb-10">
    <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
      <div v-for="item in stats" :key="item.name" class="overflow-hidden rounded-lg bg-gray-900 px-4 py-5 shadow sm:p-6 border-gray-700 border">
        <dt class="truncate text-sm font-medium text-gray-300">{{ item.name }}</dt>
        <dd class="mt-1 text-3xl font-semibold tracking-tight text-gray-100">{{ item.stat }}</dd>
      </div>
    </dl>
  </div>
</template>

<script setup>
const stats = [
  { name: 'Mails reçus par jour', stat: '897' },
  { name: 'Mails non lus', stat: '58' },
  { name: 'Mails ouverts', stat: '24' },
]
</script>