<script setup>
import { ref } from 'vue';
import Stats from "@/components/Backoffice/Decores/components/Stats.vue";
import ListUsers from "@/components/Backoffice/Decores/components/ListUsers.vue";
import FormEdit from "@/components/Backoffice/Decores/components/FormEdit.vue";

const selectedType = ref('');

const handleEditItem = (type) => {
  selectedType.value = type;
};
</script>

<template>
  <div class="mb-6">
    <h2 class="text-2xl font-semibold text-gray-200">Gestion des Décorés</h2>
    <p class="mt-1 text-sm/6 text-gray-300">Gérez et publiez les décorés MM ou MH facilement depuis le back-office.</p>
  </div>
  <Stats></Stats>
  <FormEdit :selectedType="selectedType"></FormEdit>
  <ListUsers @edit-item="handleEditItem" ></ListUsers>
</template>
