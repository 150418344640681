<template>
  <div class="py-28 flex items-center w-full bg-[#072653] min-h-[450px] mt-[136px] lg:mt-[160px]">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto max-w-2xl lg:mx-0">
        <h2 class="text-pretty text-4xl font-semibold tracking-tight text-white sm:text-5xl">MARTINIQUE 2024</h2>
      </div>
    </div>
  </div>
  <div class="bg-white">
    <main class="mx-auto max-w-7xl sm:px-6 sm:pt-16 lg:px-8">
      <div class="mx-auto max-w-2xl lg:max-w-none">
        <div class="grid-cols-1 lg:grid lg:grid-cols-2 lg:items-start lg:gap-x-8 py-24">
          <div class="px-8 gap-4 flex flex-col">
            <h1 class="text-4xl sm:text-5xl">Description du congrès</h1>
            <p>{{ congres.item[0].description }}</p>
          </div>
          <TabGroup as="div" class="flex sm:flex-col-reverse mt-24 lg:mt-0">
            <div class="mx-auto mt-6 hidden w-full max-w-2xl sm:block lg:max-w-none">
              <TabList class="grid grid-cols-4 gap-6">
                <Tab v-for="image in congres.images" :key="image.id" class="relative flex h-24 cursor-pointer items-center justify-center rounded-md bg-white text-sm font-medium uppercase text-gray-900 hover:bg-gray-50 focus:outline-none focus:ring focus:ring-indigo-500/50 focus:ring-offset-4" v-slot="{ selected }">
                  <span class="sr-only">{{ image.name }}</span>
                  <span class="absolute inset-0 overflow-hidden rounded-md">
                    <img :src="image.src" alt="" class="size-full object-cover" />
                  </span>
                  <span :class="[selected ? 'ring-indigo-500' : 'ring-transparent', 'pointer-events-none absolute inset-0 rounded-md ring-2 ring-offset-2']" aria-hidden="true" />
                </Tab>
              </TabList>
            </div>

            <TabPanels>
              <TabPanel v-for="image in congres.images" :key="image.id">
                <img :src="image.src" :alt="image.alt" class="flex w-full object-cover sm:rounded-lg" />
              </TabPanel>
            </TabPanels>
          </TabGroup>
        </div>
      </div>
    </main>
  </div>
</template>

<script setup>
import {
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
} from '@headlessui/vue'


const congres = {
  item : [
    {
      description : 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aspernatur consequuntur corporis deserunt eaque iusto, laborum molestias nisi pariatur, perspiciatis recusandae repudiandae tempore vero voluptate! Molestias, temporibus, veniam! Alias autem beatae commodi deserunt dolor dolore ea esse est eveniet exercitationem fuga fugiat, illo ipsum magni maiores nihil nulla pariatur perspiciatis placeat quis sit tenetur totam voluptatum. A ad aperiam assumenda aut corporis cupiditate deleniti dolore doloribus earum eius eligendi enim eum ex, explicabo fugiat impedit iusto maxime necessitatibus nesciunt non odit officia optio praesentium, quae quas quo quod reiciendis rem repellat similique sint sit sunt totam unde vitae voluptas voluptatibus! Alias, architecto, dicta eligendi eum, eveniet explicabo laboriosam laborum magnam modi nemo quasi unde vero.',
    }
  ],
  images: [
    {
      id: 1,
      name: 'Angled view',
      src: 'https://tailwindui.com/plus/img/ecommerce-images/product-page-03-product-01.jpg',
      alt: 'Angled front view with bag zipped and handles upright.',
    },
    {
      id: 2,
      name: 'Angled view',
      src: 'https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3603&q=80',
      alt: 'Angled front view with bag zipped and handles upright.',
    },
    {
      id: 3,
      name: 'Angled view',
      src: 'https://api.meritemaritime-fnmm.com/uploads/1725618682675.jpg',
      alt: 'Angled front view with bag zipped and handles upright.',
    },
  ],
}
</script>