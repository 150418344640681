<template>
  <div>
    <dl class="mt-5 mb-12 grid grid-cols-1 gap-5 sm:grid-cols-3">
      <div v-for="item in stats" :key="item.name" class="overflow-hidden rounded-lg bg-gray-900 px-4 py-5 shadow sm:p-6 border-gray-700 border">
        <dt class="truncate text-sm font-medium text-gray-500">{{ item.name }}</dt>
        <dd class="mt-1 text-3xl font-semibold tracking-tight text-gray-200">{{ item.stat }}</dd>
      </div>
    </dl>
  </div>
</template>

<script setup>
const stats = [
  { name: 'Nombre total d’articles', stat: '7' },
  { name: '', stat: '' },
  { name: '', stat: '' },
]
</script>