<script setup>
const actions = [
  // {
  //   title: 'Mars 2019',
  //   href: '#',
  // },
  // {
  //   title: 'Avril 2019',
  //   href: '#',
  // },
  // {
  //   title: 'Septembre 2020',
  //   href: '#',
  // },
  // {
  //   title: 'Janvier 2021',
  //   href: '#',
  // },
  // {
  //   title: 'Mars 2021',
  //   href: '#',
  // },
  // {
  //   title: 'Juillet 2021',
  //   href: '#',
  // },
  // {
  //   title: 'Septembre 2021',
  //   href: '#',
  // },
];
</script>

<template>
  <div class="py-28 w-full bg-[#072653] min-h-[450px] mt-[136px] lg:mt-[160px]">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto max-w-2xl lg:mx-0">
        <h2 class="text-pretty text-4xl font-semibold tracking-tight text-white sm:text-5xl">CENTRE DE DOCUMENTATION MARITIME</h2>
        <p class="mt-6 text-lg/8 text-gray-200">Explorez notre base de ressources dédiée au patrimoine maritime de la Fédération Nationale du Mérite Maritime et de la Médaille d'Honneur des Marins.</p>
        <div  class="grid grid-cols-1 mt-6 sm:grid-cols-2 lg:grid-cols-2 gap-x-3 gap-y-4 mx-auto w-full">
          <a href="https://president266.wixsite.com/fnmmcdm" target="_blank" class="text-gray-200 hover:text-violet-600 shadow flex py-2 rounded justify-center items-center border">
            Accéder au site du CDM
          </a>
          <a href="https://president266.wixsite.com/fnmmcdm/contacts" target="_blank" class="text-gray-200 hover:text-violet-600 shadow flex py-2 rounded justify-center items-center border">
            Contacter le CDM
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="bg-neutral-50 py-12">
    <div class="mx-auto max-w-7xl px-6">
      <h3 class="text-2xl">Documents du mois</h3>
      <div class="mt-8 lg:px-8 divide-y divide-gray-200 bg-white overflow-hidden rounded-lg shadow sm:grid sm:grid-cols-2 sm:gap-px sm:divide-y-0">
        <div v-for="(action, actionIdx) in actions" :key="action.title" :class="[actionIdx === 0 ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none' : '', actionIdx === 1 ? 'sm:rounded-tr-lg' : '', actionIdx === actions.length - 2 ? 'sm:rounded-bl-lg' : '', actionIdx === actions.length - 1 ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none' : '', 'group relative bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500']">
          <div class="flex items-center justify-between">
            <h3 class="text-base font-semibold text-gray-900 flex items-center gap-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-file-text"><path d="M15 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7Z"/><path d="M14 2v4a2 2 0 0 0 2 2h4"/><path d="M10 9H8"/><path d="M16 13H8"/><path d="M16 17H8"/></svg>
              <a :href="action.href" class="focus:outline-none">
                <span class="absolute inset-0" aria-hidden="true" />
                {{ action.title }}
              </a>
            </h3>
            <span class="pointer-events-none text-gray-300 group-hover:text-gray-400" aria-hidden="true">
        <svg class="size-6" fill="currentColor" viewBox="0 0 24 24">
          <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
        </svg>
      </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>