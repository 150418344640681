<script>
import AppFooter from "@/components/Essentials/AppFooter.vue";
import AppHeader from "@/components/Essentials/AppHeader.vue";
import Content from "@/components/CDM/Content.vue";
import Fastnav from "@/components/Home/FastNav/Fastnav.vue";

export default {
  name: 'CdmPage',
  components: {
    Fastnav,
    Content,
    AppFooter,
    AppHeader
  }
}
</script>

<template>
  <AppHeader></AppHeader>
  <Content></Content>
  <Fastnav></Fastnav>
  <AppFooter></AppFooter>
</template>
