<script setup>
import { defineEmits } from 'vue';

const emit = defineEmits(['section-click']);
const onSectionClick = (sectionTitle) => {
  emit('section-click', sectionTitle); // Transmettre le titre cliqué
};

const actions = [
  {
    title: 'Bureau National',
    href: '#',
  },
  {
    title: 'ALPES MARITIMES',
    href: '#',
  },
  {
    title: 'BOUCHES DU RHONE',
    href: '#',
  },
  {
    title: 'BRETAGNE SUD et VENDEE',
    href: '#',
  },
  {
    title: 'CALVADOS',
    href: '#',
  },
  {
    title: 'COTES D\'ARMOR',
    href: '#',
  },
  {
    title: 'CORSE',
    href: '#',
  },
  {
    title: 'FINISTERE',
    href: '#',
  },
  {
    title: 'GIRONDE',
    href: '#',
  },
  {
    title: 'GUADELOUPE',
    href: '#',
  },
  {
    title: 'ILE-DE-FRANCE',
    href: '#',
  },
  {
    title: 'ILLE-ET-VILAINE',
    href: '#',
  },
  {
    title: 'MANCHE',
    href: '#',
  },
  {
    title: 'MARTINIQUE',
    href: '#',
  },
  {
    title: 'MORBIHAN',
    href: '#',
  },
  {
    title: 'NORD-PAS-DE-CALAIS',
    href: '#',
  },
  {
    title: 'OCCITANIE',
    href: '#',
  },
  {
    title: 'POITOU-CHARENTES',
    href: '#',
  },
  {
    title: 'PYRENEES-ATLANTIQUE',
    href: '#',
  },
  {
    title: 'SOI-REUNION',
    href: '#',
  },
  {
    title: 'SEINE-MARITIME',
    href: '#',
  },
  {
    title: 'VAR',
    href: '#',
  },
];
</script>

<template>
  <div class="">
    <div class="mx-auto max-w-2xl text-center">
      <h2 class="text-balance text-4xl font-semibold tracking-tight text-gray-200 sm:text-5xl">Choix de section</h2>
      <p class="text-gray-200 text-xl mt-4">Sélectionnez une section à modifier ou supprimer, ou créez un nouvel article.</p>
    </div>
    <div class="mx-auto max-w-7xl">
      <div class="mt-8 border-gray-700 border lg:px-8 divide-y divide-gray-200 bg-gray-900 overflow-hidden rounded-lg shadow sm:grid sm:grid-cols-2 sm:gap-px sm:divide-y-0">
        <div v-for="(action, actionIdx) in actions" :key="action.title" :class="[actionIdx === 0 ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none' : '', actionIdx === 1 ? 'sm:rounded-tr-lg' : '', actionIdx === actions.length - 2 ? 'sm:rounded-bl-lg' : '', actionIdx === actions.length - 1 ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none' : '', 'group relative bg-gray-900 p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-green-500']" @click="onSectionClick(action.title)">
          <div class="flex items-center justify-between">
            <h3 class="text-base font-semibold text-gray-200">
              <a :href="action.href" class="focus:outline-none">
                <span class="absolute inset-0" aria-hidden="true" />
                {{ action.title }}
              </a>
            </h3>
            <span class="pointer-events-none text-gray-300 group-hover:text-gray-400" aria-hidden="true">
              <svg class="size-6" fill="currentColor" viewBox="0 0 24 24">
                <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
              </svg>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>