<template>
  <div class="bg-white px-6 py-32 lg:px-8">
    <div class="mx-auto max-w-3xl text-base/7 text-gray-700">
      <p class="text-base/7 font-semibold text-indigo-600">Article n°1</p>
      <h1 class="mt-2 text-pretty text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">JavaScript for beginners</h1>
      <figure class="mt-16">
        <img class="aspect-video rounded-xl bg-gray-50 object-cover" src="https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&auto=format&fit=facearea&w=1310&h=873&q=80&facepad=3" alt="" />
      </figure>
      <p class="mt-6 text-xl/8">Aliquet nec orci mattis amet quisque ullamcorper neque, nibh sem. At arcu, sit dui mi, nibh dui, diam eget aliquam. Quisque id at vitae feugiat egestas ac. Diam nulla orci at in viverra scelerisque eget. Eleifend egestas fringilla sapien.</p>
    </div>
  </div>

  <div class="bg-neutral-50 py-24 sm:py-32">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <h2 class="max-w-2xl mb-20 text-balance text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">Décrouvez d'autres articles</h2>
      <div class="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
        <a v-for="post in posts.slice(0, 6)" :key="post.id" :href="post.href" class="flex flex-col items-start justify-between">
          <div class="relative w-full">
            <img :src="post.imageUrl" alt="" class="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]" />
            <div class="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
          </div>
          <div class="max-w-xl">
            <div class="mt-8 flex items-center gap-x-4 text-xs">
              <time :datetime="post.datetime" class="text-gray-500">{{ post.date }}</time>
            </div>
            <div class="group relative">
              <h3 class="mt-3 text-lg font-semibold text-gray-900 group-hover:text-gray-600">
                <span>
                  <span class="absolute inset-0" />
                  {{ post.title }}
                </span>
              </h3>
              <p class="mt-5 line-clamp-3 text-sm text-gray-600">{{ post.description }}</p>
            </div>
          </div>
        </a>
      </div>

    </div>
  </div>
</template>

<script setup>
const posts = [
  {
    id: 1,
    title: 'Boost your conversion rate',
    href: '/nouvelles-de-la-federation/article/1',
    description:
        'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
    imageUrl:
        'https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3603&q=80',
    date: 'Mar 16, 2020',
    datetime: '2020-03-16',
  },
  {
    id: 2,
    title: 'How to use search engine',
    href: '/',
    description:
        'Optio cumque nihil impedit distinctio. Quae consequatur architecto voluptate facilis libero vel fugiat voluptate assumenda.',
    imageUrl:
        'https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3603&q=80',
    date: 'Apr 10, 2020',
    datetime: '2020-04-10',
  },
  {
    id: 3,
    title: 'Improve your customer experience',
    href: '/',
    description:
        'Magnam similique facere. Error aperiam voluptates omnis facere quaerat reprehenderit rerum dolorum. Deserunt voluptate est quia occaecati voluptatem.',
    imageUrl:
        'https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3603&q=80',
    date: 'May 8, 2020',
    datetime: '2020-05-08',
  },
  {
    id: 4,
    title: 'How to improve your retention rates',
    href: '/',
    description:
        'Et enim voluptate laborum voluptatem id. Consequatur suscipit nihil assumenda odio tempora. Et id soluta et molestiae molestiae.',
    imageUrl:
        'https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3603&q=80',
    date: 'Jun 1, 2020',
    datetime: '2020-06-01',
  } ,
  {
    id: 5,
    title: 'Improve your customer experience',
    href: '/',
    description:
        'Magnam similique facere. Error aperiam voluptates omnis facere quaerat reprehenderit rerum dolorum. Deserunt voluptate est quia occaecati voluptatem.',
    imageUrl:
        'https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3603&q=80',
    date: 'May 8, 2020',
    datetime: '2020-05-08',
  },
  {
    id: 6,
    title: 'How to improve your retention rates',
    href: '/',
    description:
        'Et enim voluptate laborum voluptatem id. Consequatur suscipit nihil assumenda odio tempora. Et id soluta et molestiae molestiae.',
    imageUrl:
        'https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3603&q=80',
    date: 'Jun 1, 2020',
    datetime: '2020-06-01',
  }
  ,
  {
    id: 7,
    title: 'Improve your customer experience',
    href: '/',
    description:
        'Magnam similique facere. Error aperiam voluptates omnis facere quaerat reprehenderit rerum dolorum. Deserunt voluptate est quia occaecati voluptatem.',
    imageUrl:
        'https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3603&q=80',
    date: 'May 8, 2020',
    datetime: '2020-05-08',
  },
  {
    id: 8,
    title: 'How to improve your retention rates',
    href: '/',
    description:
        'Et enim voluptate laborum voluptatem id. Consequatur suscipit nihil assumenda odio tempora. Et id soluta et molestiae molestiae.',
    imageUrl:
        'https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3603&q=80',
    date: 'Jun 1, 2020',
    datetime: '2020-06-01',
  }
  ,
  {
    id: 9,
    title: 'Improve your customer experience',
    href: '/',
    description:
        'Magnam similique facere. Error aperiam voluptates omnis facere quaerat reprehenderit rerum dolorum. Deserunt voluptate est quia occaecati voluptatem.',
    imageUrl:
        'https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3603&q=80',
    date: 'May 8, 2020',
    datetime: '2020-05-08',
  },
  {
    id: 10,
    title: 'How to improve your retention rates',
    href: '/',
    description:
        'Et enim voluptate laborum voluptatem id. Consequatur suscipit nihil assumenda odio tempora. Et id soluta et molestiae molestiae.',
    imageUrl:
        'https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3603&q=80',
    date: 'Jun 1, 2020',
    datetime: '2020-06-01',
  }
]
</script>