<template>
  <div class="pb-24 sm:pb-32">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto max-w-2xl text-center">
        <h2 class="text-balance text-4xl font-semibold tracking-tight text-gray-200 sm:text-5xl">Articles Fédération</h2>
        <div class="col-span-full">
          <div class="mt-4">
            <input type="text" name="search" id="search" placeholder="Chercher un article" class="mt-2 px-4 py-4 block w-full rounded-md border-0 bg-white/5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm/6" />
          </div>
        </div>
      </div>
      <div class="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-2">
        <button type="button" class="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2" @click="$emit('view-article')">
          <svg class="mx-auto size-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 4h24l8 8v32H6V4z" />
            <line x1="12" y1="20" x2="32" y2="20" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></line>
            <line x1="12" y1="26" x2="28" y2="26" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></line>
            <line x1="12" y1="32" x2="24" y2="32" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></line>
          </svg>
          <span class="mt-2 block text-sm font-semibold text-white">Ajouter un article</span>
        </button>
        <article v-for="post in posts" :key="post.id" class="flex flex-col items-start justify-between bg-gray-900 p-6 border-gray-700 border rounded-xl hover:bg-gray-700 shadow cursor-pointer">
          <div class="relative w-full">
            <img :src="post.imageUrl" alt="" class="aspect-video w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]" />
            <div class="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
          </div>
          <div class="max-w-xl">
            <div class="mt-8 flex items-center gap-x-4 text-xs">
              <time :datetime="post.datetime" class="text-gray-300">{{ post.date }}</time>
            </div>
            <div class="group relative">
              <h3 class="mt-3 text-lg/6 font-semibold text-gray-100">
                <a :href="post.href">
                  <span class="absolute inset-0" />
                  {{ post.title }}
                </a>
              </h3>
              <p class="mt-5 line-clamp-3 text-sm/6 text-gray-200">{{ post.description }}</p>
            </div>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script setup>
const posts = [
  {
    id: 1,
    title: 'Boost your conversion rate',
    href: '#',
    description:
        'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
    imageUrl:
        'https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3603&q=80',
    date: 'Mar 16, 2020',
    datetime: '2020-03-16',
  },
  {
    id: 2,
    title: '10 Tips for Writing Engaging Blog Posts',
    href: '#',
    description:
        'Discover the secrets to writing blog posts that keep readers hooked from start to finish. Learn tips, tricks, and best practices for content creation.',
    imageUrl:
        'https://images.unsplash.com/photo-1519337265831-281ec6cc8514?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3600&q=80',
    date: 'Apr 2, 2021',
    datetime: '2021-04-02',
  },
  {
    id: 3,
    title: 'The Future of AI in Marketing',
    href: '#',
    description:
        'Artificial Intelligence is reshaping the marketing landscape. Explore how AI is being used to predict trends, personalize experiences, and optimize campaigns.',
    imageUrl:
        'https://images.unsplash.com/photo-1519337265831-281ec6cc8514?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3600&q=80',
    date: 'Jan 15, 2022',
    datetime: '2022-01-15',
  },
  {
    id: 4,
    title: 'Mastering SEO for Beginners',
    href: '#',
    description:
        'SEO doesn’t have to be complicated. This guide breaks down the basics to help you improve your website’s ranking on search engines.',
    imageUrl:
        'https://images.unsplash.com/photo-1507525428034-b723cf961d3e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3600&q=80',
    date: 'Feb 10, 2023',
    datetime: '2023-02-10',
  },
  {
    id: 5,
    title: 'Top Tools for Remote Teams in 2024',
    href: '#',
    description:
        'Remote work is here to stay. Discover the best tools to keep your remote team productive, connected, and thriving in 2024.',
    imageUrl:
        'https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3600&q=80',
    date: 'Nov 15, 2024',
    datetime: '2024-11-15',
  },
  {
    id: 6,
    title: 'How to Build a Winning Social Media Strategy',
    href: '#',
    description:
        'Social media is an essential marketing tool. Learn how to create a strategy that grows your audience and drives engagement.',
    imageUrl:
        'https://images.unsplash.com/photo-1519337265831-281ec6cc8514?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3600&q=80',
    date: 'Aug 8, 2023',
    datetime: '2023-08-08',
  },
  {
    id: 7,
    title: 'The Importance of Cybersecurity in 2024',
    href: '#',
    description:
        'With increasing cyber threats, protecting your digital assets is more important than ever. Learn how to safeguard your data and maintain trust.',
    imageUrl:
        'https://images.unsplash.com/photo-1519337265831-281ec6cc8514?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3600&q=80',
    date: 'Oct 10, 2024',
    datetime: '2024-10-10',
  },
];

</script>