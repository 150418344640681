<script setup>
import { ref } from 'vue';
import ListSections from "@/components/Backoffice/GestionDesArticles/Sections/components/ListSections.vue";
import Stats from "@/components/Backoffice/GestionDesArticles/Sections/components/Stats.vue";
import ListArticles from "@/components/Backoffice/GestionDesArticles/Sections/components/ListArticles.vue";
import ViewArticle from "@/components/Backoffice/GestionDesArticles/Sections/components/ViewArticle.vue";

const showListSections = ref(true);
const selectedSectionTitle = ref('');

const handleSectionClick = (sectionTitle) => {
  selectedSectionTitle.value = sectionTitle;
  showListSections.value = false;
};

const handleReturnToSections = () => {
  showListSections.value = true;
  selectedSectionTitle.value = '';
};
</script>


<template>
  <div class="mb-6">
    <h2 class="text-2xl font-semibold text-gray-200">Gestion des articles</h2>
    <p class="mt-1 text-sm/6 text-gray-300">Gérez et publiez vos articles facilement depuis le back-office.</p>
  </div>
  <Stats></Stats>
  <div v-if="showListSections">
    <ListSections @section-click="handleSectionClick"></ListSections>
  </div>
  <div v-else>
    <ListArticles
        :section-title="selectedSectionTitle"
        @return-to-sections="handleReturnToSections">
    </ListArticles>
  </div>
  <ViewArticle></ViewArticle>
</template>
