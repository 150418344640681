<template>
  <div>
    <div class="px-4 sm:px-0">
      <h3 class="text-2xl font-semibold text-white mb-4">Documents destinés aux membres</h3>
    </div>
    <div class="bg-gray-900 p-6 rounded-xl border-gray-700 border">
      <div class="col-span-full">
        <label for="file-upload" class="block text-sm/6 font-medium text-white">Sélectionner un fichier</label>
        <label for="file-upload" class="mt-2 flex justify-center rounded-lg border border-dashed border-gray-200/25 px-6 py-10  cursor-pointer">
          <div class="text-center">
            <svg class="mx-auto size-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 4h24l8 8v32H6V4z" />
              <line x1="12" y1="20" x2="32" y2="20" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></line>
              <line x1="12" y1="26" x2="28" y2="26" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></line>
              <line x1="12" y1="32" x2="24" y2="32" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></line>
            </svg>
            <div class="mt-4 flex text-sm/6 text-gray-600">
              <label for="file-upload" class="relative cursor-pointer rounded-md font-semibold text-white focus-within:outline-none focus-within:ring-2 focus-within:ring-green-600 focus-within:ring-offset-2 focus-within:ring-offset-gray-900 hover:text-green-500">
                <span>Choisir un fichier</span>
                <input id="file-upload" name="file-upload" type="file" class="sr-only" />
              </label>
              <p class="pl-1">ou glisser-déposer ici</p>
            </div>
            <p class="text-xs/5 text-gray-600">PDF, jusqu'à 50MB</p>
          </div>
        </label>
        <div class="w-full flex justify-end mt-4">
          <button type="submit" class="rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600">Ajouter</button>
        </div>
      </div>
      <div class="col-span-full mt-6">
        <label for="title" class="block text-sm/6 font-medium text-white">URL</label>
        <div class="mt-2">
          <input type="text" name="title" id="title" placeholder="-" class="mt-2 px-4 py-4 block w-full rounded-md border-0 bg-white/5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm/6" />
        </div>
        <div class="w-full flex justify-end mt-4">
          <button type="submit" class="rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600">Ajouter</button>
        </div>
      </div>
    </div>

    <div class="mt-16 border-t border-white/10 py-28">
      <dl class="divide-y divide-white/10">
        <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
          <dt class="text-sm/6 font-medium text-white">Tout les liens</dt>
          <dd class="mt-2 text-sm text-white sm:col-span-2 sm:mt-0">
            <ul role="list" class="divide-y divide-white/10 rounded-md border border-white/20">
              <li v-for="link in link" :key="link.title" class="flex items-center justify-between py-4 pl-4 pr-5 text-sm/6">
                <div class="flex w-0 flex-1 items-center">
                  <PaperClipIcon class="size-5 shrink-0 text-gray-400" aria-hidden="true" />
                  <div class="ml-4 flex min-w-0 flex-1 gap-2">
                    <span class="truncate font-medium">{{ link.title }}</span>
                  </div>
                </div>
                <div class="ml-4 shrink-0 items-center flex gap-4">
                  <a :href="link.href" class="font-medium text-green-400 hover:text-green-300">Accéder</a>
                  <button><TrashIcon class="w-5 h-5 shrink-0 text-gray-400 hover:text-red-400" aria-hidden="true" /></button>
                </div>
              </li>
            </ul>
          </dd>
        </div>
      </dl>
    </div>
    <div class="border-t border-white/10 py-28">
      <dl class="divide-y divide-white/10">
        <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
          <dt class="text-sm/6 font-medium text-white">Tout les fichiers</dt>
          <dd class="mt-2 text-sm text-white sm:col-span-2 sm:mt-0">
            <ul role="list" class="divide-y divide-white/10 rounded-md border border-white/20">
              <li v-for="member in member" :key="member.title" class="flex items-center justify-between py-4 pl-4 pr-5 text-sm/6">
                <div class="flex w-0 flex-1 items-center">
                  <PaperClipIcon class="size-5 shrink-0 text-gray-400" aria-hidden="true" />
                  <div class="ml-4 flex min-w-0 flex-1 gap-2">
                    <span class="truncate font-medium">{{ member.title }}</span>
                    <span class="shrink-0 text-gray-400">{{ member.size }}</span>
                  </div>
                </div>
                <div class="ml-4 shrink-0 items-center flex gap-4">
                  <a :href="member.href" class="font-medium text-green-400 hover:text-green-300">Télécharger</a>
                  <button><TrashIcon class="w-5 h-5 shrink-0 text-gray-400 hover:text-red-400" aria-hidden="true" /></button>
                </div>
              </li>
            </ul>
          </dd>
        </div>
      </dl>
    </div>

  </div>
</template>

<script setup>
import { PaperClipIcon, TrashIcon } from '@heroicons/vue/20/solid'
import {FileIcon} from "@heroicons/vue/24/solid";

const link = [
  {
    title : 'https://www.meritemaritime-fnmm.com/exemple-de-lien',
    href : 'https://www.meritemaritime-fnmm.com/exemple-de-lien',
  },
  {
    title : 'https://www.meritemaritime-fnmm.com/exemple-de-lien',
    href : 'https://www.meritemaritime-fnmm.com/exemple-de-lien',
  },
  {
    title : 'https://www.meritemaritime-fnmm.com/exemple-de-lien',
    href : 'https://www.meritemaritime-fnmm.com/exemple-de-lien',
  },
  {
    title : 'https://www.meritemaritime-fnmm.com/exemple-de-lien',
    href : 'https://www.meritemaritime-fnmm.com/exemple-de-lien',
  },
]

const member = [
  {
    title: 'financial_report_q3.pdf',
    size: '2.9mb',
    href: '/documents/financial_report_q3.pdf',
  },
  {
    title: 'marketing_strategy_2024.pdf',
    size: '3.5mb',
    href: '/documents/marketing_strategy_2024.pdf',
  },
  {
    title: 'employee_handbook.pdf',
    size: '5.0mb',
    href: '/documents/employee_handbook.pdf',
  },
  {
    title: 'product_roadmap.pdf',
    size: '2.7mb',
    href: '/documents/product_roadmap.pdf',
  },
  {
    title: 'meeting_notes_october.pdf',
    size: '1.6mb',
    href: '/documents/meeting_notes_october.pdf',
  },
  {
    title: 'company_overview.pdf',
    size: '3.8mb',
    href: '/documents/company_overview.pdf',
  },
];
</script>