<template>
  <div class="py-28 w-full bg-[#072653] min-h-[450px] mt-[136px] lg:mt-[160px]">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto max-w-2xl lg:mx-0">
        <h2 class="text-pretty text-4xl font-semibold tracking-tight text-white sm:text-5xl">HISTOIRE DU MÉRITE MARITIME</h2>
        <p class="mt-6 text-lg/8 text-gray-200">Découvrez l'histoire fascinante de la Fédération Nationale du Mérite Maritime et de la Médaille d'Honneur des Marins.</p>
      </div>
    </div>
  </div>
  <div class="bg-white px-6 py-32 lg:px-8">
    <div class="mx-auto max-w-3xl text-base/7 text-gray-700">
      <div class="mt-10 max-w-2xl">
        <h2 class="text-pretty text-3xl font-semibold tracking-tight text-gray-900">1901</h2>
        <p class="mt-6">Pour récompenser les bons et loyaux services des marins français, il a d'abord été créé la Médaille d'Honneur des Marins du Commerce et de la Pêche.</p>

        <h2 class="mt-16 text-pretty text-3xl font-semibold tracking-tight text-gray-900">1930</h2>
        <p class="mt-6">Il est institué l'Ordre du Mérite Maritime, destiné à récompenser la valeur professionnelle des marins et le mérite des citoyens qui se sont distingués pour le développement de la Marine Marchande, des Ports, des Pêches et des Sports Nautiques.</p>

        <h2 class="mt-16 text-pretty text-3xl font-semibold tracking-tight text-gray-900">30 mars 1954<br>Création de la Fédération Nationale du Mérite Maritime et de la Médaille d'Honneur des Marins du Commerce et de la Pêche</h2>
        <p class="mt-6">Monsieur D-M. Cossemille, intendant de la Marine Marchande, et M. Antoine Chaix, Officier radioélectricien de 1ère classe de la Marine Marchande, fondèrent une association ayant pour objectif de préserver le prestige de l'Ordre. Cette association dénommée "Fédération Nationale du Mérite Maritime et de la Médaille d'Honneur des Marins du Commerce et de la Pêche", était déclarée le 8 septembre 1954.</p>

        <p class="mt-6">Elle est administrée par un conseil d'administration de 15 membres minimum.</p>

        <p class="mt-6">Elle a pour buts :</p>

        <ul role="list" class="mt-8 max-w-xl space-y-8 text-gray-600">
          <li class="flex gap-x-3">
            <CheckCircleIcon class="mt-1 size-5 flex-none text-black" aria-hidden="true" />
            <span><strong class="font-semibold text-gray-900"></strong> de veiller au prestige de l'Ordre du Mérite Maritime et de la Médaille d'Honneur.</span>
          </li>
          <li class="flex gap-x-3">
            <CheckCircleIcon class="mt-1 size-5 flex-none text-black" aria-hidden="true" />
            <span><strong class="font-semibold text-gray-900"></strong> de créer et de maintenir l'union entre tous ses membres par des contacts fréquents.</span>
          </li>
          <li class="flex gap-x-3">
            <CheckCircleIcon class="mt-1 size-5 flex-none text-black" aria-hidden="true" />
            <span><strong class="font-semibold text-gray-900"></strong> de soutenir moralement et matériellement ses adhérents en difficulté.</span>
          </li>
        </ul>

        <p class="mt-8">La haute valeur du Mérite Maritime a été reconnue et appréciée par le général Charles De Gaulle, qui a maintenu notre Ordre en 1963, après intervention de M. Jean Morin, secrétaire général de la Marine Marchande.</p>

        <h2 class="mt-16 text-pretty text-3xl font-semibold tracking-tight text-gray-900">Moyens d'action</h2>
        <p class="mt-6">Liaison permanente avec le ministère et la direction des Affaires Maritimes. Fréquentes réunions d'information. Congrès annuel avec assemblée générale, conférences et débats. Repas amicaux. Publication et diffusion du bulletin (aux alentours du mois d'août) et du mini-bulletin en février. Contact avec toutes les associations de marins et d'anciens marins. Edition de l'annuaire des membres (mise à jour annuelle).La Fédération comprend des sections réparties dans toute la France et l'Outre-mer.</p>
        <p class="mt-10">Les ressources principales de la Fédération sont avant tout, les cotisations de ses membres; il faut y ajouter des subventions et intérêts des divers placements. La gamme des cotisations permet à tous de contribuer à l'oeuvre commune.</p>
        <p class="mt-10">Il faut retenir que le fait de recevoir le Mérite Maritime ou la Médaille d'Honneur des Marins, et l'honneur qui s'attache à ces distinctions, impliquent pour les titulaires quelques devoirs, dont celui de contribuer à la vie de l'association dont ils font partie.</p>

      </div>
      <div class="mt-16 max-w-2xl">
        <h2 class="text-pretty text-3xl font-semibold tracking-tight text-gray-900">Les grandes étapes</h2>
        <p class="mt-6"><strong>14 février 1902</strong> Institution de la Médaille d'Honneur des Marins du Commerce et de la Pêche.</p>
        <p class="mt-6"><strong>9 février 1930</strong> Institution de l'Ordre du Mérite Maritime.</p>
        <p class="mt-6"><strong>30 mars 1954</strong> Création à Marseille de l'Association du Mérite Maritime et de la Médaille d'Honneur des Marins.</p>
        <p class="mt-6"><strong>8 septembre 1954</strong> Création à Marseille de la Fédération Nationale du Mérite Maritime et de la Médaille d'Honneur des Marins.</p>
        <p class="mt-6"><strong>3 décembre 1963</strong> Création par le général De Gaulle, de l'Ordre National du Mérite, se substituant à 16 ordres ministériels et coloniaux. L'Ordre du Mérite Maritime est conservé.</p>
        <p class="mt-6"><strong>26 avril 1980</strong> Cérémonie du Cinquantenaire du Mérite Maritime à Dunkerque.</p>
        <p class="mt-6"><strong>17 janvier 2002</strong> Décret n° 2002-88, régissant l'Ordre du Mérite Maritime institué par la loi de 1930.</p>
        <p class="mt-6"><strong>24 mai 2002</strong> Cérémonie du Centenaire de la Médaille d'Honneur des Marins du Commerce et de la Pêche, à Paris.</p>
        <p class="mt-6"><strong>13 mai 2004</strong> Cérémonie du cinquantenaire de la Fédération à Marseille (à bord du CF "Danielle Casanova").</p>

        <h2 class="mt-16 text-pretty text-3xl font-semibold tracking-tight text-gray-900">Les présidents</h2>
        <div class="bg-white">
          <div class="mx-auto max-w-7xl px-6 lg:px-8">
            <ul role="list" class="mx-auto my-20 grid max-w-2xl grid-cols-2 gap-x-8 gap-y-16 text-center sm:grid-cols-3 md:grid-cols-3 lg:mx-0 lg:max-w-none lg:grid-cols-2 xl:grid-cols-3">
              <li v-for="person in presidents" :key="person.name">
                <img class="mx-auto size-32 object-cover rounded-full" :src="person.imageUrl" alt="" />
                <h3 class="mt-6 text-base/7 font-semibold tracking-tight text-gray-900">{{ person.name }}</h3>
                <p class="text-sm/6 text-gray-600">{{ person.role }}</p>
              </li>
            </ul>
          </div>
        </div>

        <h2 class="mt-16 text-pretty text-3xl font-semibold tracking-tight text-gray-900">Les secrétaires généraux</h2>
        <ul role="list" class="mt-6 mx-auto grid max-w-2xl grid-cols-1 gap-x-6 gap-y-20 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:gap-x-8 xl:col-span-2">
          <li v-for="person in people" :key="person.name">
            <h3 class="mt-6 text-lg/8 font-semibold text-gray-900">{{ person.name }}</h3>
            <p class="text-base/7 text-gray-600">{{ person.role }}</p>
            <p class="mt-4 text-base/7 text-gray-600">{{ person.bio }}</p>
          </li>
        </ul>

        <h2 class="mt-16 text-pretty text-3xl font-semibold tracking-tight text-gray-900">Le mérite maritime</h2>
        <p class="mt-6"><strong>L'Ordre du "Mérite Maritime" a été institué par la loi du 9 février 1930</strong> pour récompenser la valeur professionnelle des marins et le mérite des citoyens qui se sont distingués par des services particuliers pour le développement et le rayonnement des activités maritimes. (La formule initiale était : pour le développement de la Marine Marchande, des ports, des pêches et des sports nautiques).</p>
        <p class="mt-6"><strong>L’ordre du Mérite maritime, qui comprend des chevaliers, des officiers et des commandeurs est régi par les textes ci-après actuellement en vigueur :</strong></p>
        <ul role="list" class="mt-8 max-w-xl space-y-8 text-gray-600">
          <li class="flex gap-x-3">
            <CheckCircleIcon class="mt-1 size-5 flex-none text-black" aria-hidden="true" />
            <span><strong class="font-semibold text-gray-900">Arrêté du 5 novembre 2009 relatif à la remise des insignes de l’ordre du Mérite maritime.</strong></span>
          </li>
        </ul>
        <p class="mt-6"><strong>Circulaire du 13 février 2017 relative à l’instruction des candidatures et promotions pour le Mérite maritime</strong></p>
        <p class="mt-6"><strong>Décret 2021-1119 du 25 août 2021</strong> pour modification du Décret 2002-88 du 17 janvier 2002 <strong>relatif à l'Ordre du Mérite maritime</strong></p>

        <h2 class="mt-16 text-pretty text-3xl font-semibold tracking-tight text-gray-900">Principaux renseignements</h2>
        <p class="mt-6">Pour être admis chevalier il faut justifier au moins de dix années de services et d'activités maritimes particuliers et exceptionnels, durée réduite dans le cas d'actes d'héroïsme ou de dévouement en mer.</p>
        <p class="mt-6">Pour être promu aux grades d'officier (après 5 ans au grade de chevalier) puis de commandeur (après 5 ans au grade d'officier) il faut justifier, à chaque fois, de mérites nouveaux (précités ci-dessus) depuis la précédente nomination ou promotion.</p>
        <p class="mt-6">Les services exceptionnels, notamment les faits d’héroïsme et de dévouement accomplis en mer ainsi que les actes nettement caractérisés concourant au rayonnement du monde maritime, peuvent dispenser des conditions de durée de services, sous la réserve expresse de ne franchir aucun grade.</p>
        <p class="mt-6">Les nominations et promotions sont prononcées chaque année à l'occasion du 1er janvier et de la fête nationale du 14 juillet par décret du Premier ministre pris sur les rapports conjoints du ministre chargé de la mer et du ministre des armées rapports reposant eux-mêmes sur les propositions du conseil de l'ordre.</p>

        <h2 class="mt-16 text-pretty text-3xl font-semibold tracking-tight text-gray-900">Public des récipiendaires</h2>
        <p class="mt-6">Le nombre de croix du Mérite maritime est fixé pour chaque grade par un arrêté annuel du ministre chargé de la mer, sur proposition du conseil de l'ordre. Elles sont réparties en trois contingents :</p>
        <p class="mt-6"><strong>Au titre du contingent A</strong>, elles peuvent être attribuées au personnel navigant de la marine marchande, des administrations civiles de l'Etat et des équipages des canots de sauvetage de toute société agréée par l'Etat, ainsi qu'aux personnes s'étant distinguées dans les sports nautiques.</p>
        <p class="mt-6"><strong>Au titre du contingent B</strong>, elles peuvent être attribuées au personnel militaire du ministère de la défense.</p>
        <p class="mt-6"><strong>Au titre du contingent C</strong>, elles peuvent être attribuées aux autres personnes qui se sont distinguées pour le développement et le rayonnement des activités maritimes, notamment dans le domaine de la marine marchande, de la pêche, des cultures marines, de l’administration maritime, des services de santé, des industries (construction navale, énergies maritimes renouvelables, activités portuaires) et des services liées à la mer, des associations maritimes (élus et organisations professionnelles et syndicales), de la protection de l’environnement littoral et marin, de la recherche océanographique et maritime, de l’enseignement maritime, de la surveillance et de la sécurité maritime, de l’ingénierie et du conseil maritime, du courtage, de la plaisance, du tourisme maritime, de la préservation et de la valorisation du patrimoine culturel maritime, enfin dans le domaine de la diplomatie maritime.</p>
        <p class="mt-6">La croix du Mérite maritime peut être conférée à titre posthume.</p>
        <p class="mt-6">Cet arrêté précise notamment les ratios de parité homme/femme imposés pour chacun des trois contingents en cohérence avec les ratios imposés pour les deux ordres nationaux. Pour le contingent B, le ratio de parité est fixé sur avis conforme du ministre de la défense</p>

        <h2 class="mt-16 text-pretty text-3xl font-semibold tracking-tight text-gray-900">Procédure</h2>
        <p class="mt-6">Les propositions au titre des contingents A et C sont établies par les directions départementales des territoires et de la mer. Les dossiers de proposition sont ensuite visés par les préfets de département de résidence et transmis aux directeurs interrégionaux de la mer. Les directions interrégionales de la mer proposent, à leur niveau, des candidatures complémentaires qui seront également visées par les préfets de département.</p>
        <p class="mt-6">L'ensemble est transmis au conseil de l’Ordre lequel établit la liste des propositions soumises à la décision du ministre chargé de la mer. Les candidatures et les propositions qui concernent les personnes du contingent B sont transmises par la voie hiérarchique au ministre des armées qui les transmet pour décision au ministre chargé de la mer, après examen par le conseil de l’Ordre.</p>
        <p class="mt-6">Le ministre chargé de la mer arrête la liste proposée à l'agrément du Premier ministre.</p>

        <h2 class="mt-16 text-pretty text-3xl font-semibold tracking-tight text-gray-900">Insignes</h2>
        <p class="mt-6">Les insignes s'inspirent de symboles marins :</p>
        <p class="mt-6">Une étoile en forme de rose des vents à seize branches sur laquelle est appliquée une ancre. Les huit branches principales sont ornées d'émail blanc. Au centre de l'avers figure une effigie de la République française vue de face et en exergue l'inscription "REPUBLIQUE FRANCAISE" sur fond d'émail bleu. Au centre du revers, sont inscrits les mots "MERITE MARITIME" avec en exergue la mention "MARINE MARCHANDE" sur fond d'émail bleu. Le diamètre de l'insigne est de 40mm en argent pour les chevaliers, 40mm en vermeil pour les officiers et de 57mm en vermeil pour les commandeurs. L'insigne est suspendu à un ruban d'une largeur de 37mm, bleu outremer avec deux liserés verts sur chaque bord; il comporte une rosette pour les officiers. L'insigne des commandeurs est suspendu à une cravate.</p>

        <h2 class="mt-16 text-pretty text-3xl font-semibold tracking-tight text-gray-900">Cérémonial de remise des insignes du Mérite Maritime</h2>
        <p class="mt-6">Aucune cérémonie de remise des insignes de l'Ordre du Mérite Maritime n'est obligatoire aux termes des textes qui régissent cet Ordre toutefois le cérémonial est encadré par l'arrêté du 5 novembre 2009.</p>
        <p class="mt-6">Lorsqu’il n’est pas procédé à la remise de l’insigne de l’Ordre du Mérite maritime par un membre de cet Ordre d’un grade au moins égal à celui du récipiendaire, cet insigne peut également être remis par :</p>
        <ul role="list" class="mt-8 max-w-xl space-y-8 text-gray-600">
          <li class="flex gap-x-3">
            <CheckCircleIcon class="mt-1 size-5 flex-none text-black" aria-hidden="true" />
            <span>le représentant de l’Etat dans le département ;</span>
          </li>
          <li class="flex gap-x-3">
            <CheckCircleIcon class="mt-1 size-5 flex-none text-black" aria-hidden="true" />
            <span>l’ambassadeur, chef de mission diplomatique, lorsque la remise a lieu sur le territoire d’un Etat étranger ;</span>
          </li>
          <li class="flex gap-x-3">
            <CheckCircleIcon class="mt-1 size-5 flex-none text-black" aria-hidden="true" />
            <span>un membre de l’ordre de la Légion d’honneur ou de l’ordre national du Mérite, d’un grade au moins égal à celui du récipiendaire ;</span>
          </li>
          <li class="flex gap-x-3">
            <CheckCircleIcon class="mt-1 size-5 flex-none text-black" aria-hidden="true" />
            <span>un membre du conseil de l’ordre du Mérite maritime ;</span>
          </li>
          <li class="flex gap-x-3">
            <CheckCircleIcon class="mt-1 size-5 flex-none text-black" aria-hidden="true" />
            <span>le chef de service départemental chargé des affaires de la mer.</span>
          </li>
        </ul>
        <p class="mt-6">La remise de l’insigne est accompagnée de la phrase suivante :</p>
        <p class="mt-6">« X (prénom), Y (nom), au nom du Gouvernement de la République, nous vous faisons chevalier (officier ou commandeur) du Mérite maritime. »</p>
        <p class="mt-6">Celui (ou celle) qui remet la décoration devra, lors de la cérémonie, être porteur de sa (ou de ses) décoration(s) pendante(s). Il est d'usage, avant la remise de l'insigne proprement dite, de rappeler brièvement dans une allocution, la carrière du récipiendaire, ses mérites et ses valeurs. La remise de l'insigne est suivie d'un geste amical à l'égard du décoré (poignée de main) qui marque la relation, le respect et l'hommage à cette personne.</p>
        <p class="mt-6">Le décoré est invité à exprimer ses remerciements au regard de l'honneur reçu et auprès de ceux qui ont compté dans son parcours. Une tenue soignée, le port des insignes honorifiques, un éloge amical nourri de souvenirs personnels et d'émotions sont des garanties de valorisation des honneurs transmis. La cérémonie est un moment solennel qui marque l'attachement des personnes présentes à l'Ordre du Mérite maritime.</p>

      </div>
    </div>
  </div>
</template>

<script setup>
import { CheckCircleIcon } from '@heroicons/vue/20/solid'

const presidents = [
  {
    name: 'Monsieur COSSEMILLE D.M.,',
    role: 'Intendant de la Marine Marchande. Du 30 mars 1954 Au 28 mars 1958',
    imageUrl:
        'https://api.meritemaritime-fnmm.com/assets/membre/1Cossemille.jpg',
  },
  {
    name: 'Monsieur LEDUC J',
    role: 'Capitaine au Long Cours. Du 29 mars 1958 Au 27 avril 1979',
    imageUrl:
        'https://api.meritemaritime-fnmm.com/assets/membre/2Leduc.jpg',
  },
  {
    name: 'Monsieur BARETGE A',
    role: 'Capitaine au Long Cours. Du 28 avril 1979 Au 30 novembre 1988',
    imageUrl:
        'https://api.meritemaritime-fnmm.com/assets/membre/3Baretge.jpg',
  },
  {
    name: 'Monsieur HELBERT A',
    role: 'Capitaine au Long Cours. Du 1er décembre 1988 Au 27 octobre 2001',
    imageUrl:
        'https://api.meritemaritime-fnmm.com/assets/membre/4Helbert.jpg',
  },
  {
    name: 'Monsieur JUPIN R',
    role: 'Capitaine au Long Cours. Du 4 décembre 2001 Au 16 mai 2003',
    imageUrl:
        'https://api.meritemaritime-fnmm.com/assets/membre/5Jupin.jpg',
  },
  {
    name: 'Monsieur BERTHOU R',
    role: 'Capitaine de la Marine Marchande. Du 17 mai 2003 au 28 mai 2009',
    imageUrl:
        'https://api.meritemaritime-fnmm.com/assets/membre/6Berthou.jpg',
  },
  {
    name: 'Monsieur DUFORT   J-C',
    role: 'Capitaine côtier. Du 28 mai 2009 au 28 mai 2015',
    imageUrl:
        'https://api.meritemaritime-fnmm.com/assets/membre/7Dufort.jpg',
  },
  {
    name: 'Madame Marie-Christine HERVOUËT-DION',
    role: 'Juriste. Du 28 mai 2015 au 3 mai 2018',
    imageUrl:
        'https://api.meritemaritime-fnmm.com/assets/membre/8Dion.jpg',
  },
  {
    name: 'Monsieur DENAYER  J-C',
    role: 'Professeur de l\'enseignement maritime. Du 3 mai 2018 au 27 mai 2021',
    imageUrl:
        'https://api.meritemaritime-fnmm.com/assets/membre/9DENAYER.jpg',
  },
  {
    name: 'Monsieur VIOLA  Fabrice',
    role: 'Capitaine de la Navigation Maritime. Du 27 mai 2021 à ce jour',
    imageUrl:
        'https://api.meritemaritime-fnmm.com/uploads/1730970743835.jpg',
  },
]


const people = [
  {
    name: 'Monsieur CHAIX A',
    role: 'Officier Radioélectricien de 1ère classe',
    bio: 'Du 30 mars 1954 Au 22 novembre 1973',
  },
  {
    name: 'Monsieur SALABERT E',
    role: 'Officier Radioélectricien',
    bio: 'Du 7 avril 1973 Au 9 octobre 1974',
  },
  {
    name: 'Monsieur ROUGIER   L',
    role: 'Capitaine au Long Cours',
    bio: 'Du 10 octobre 1974 Au 21 décembre 1978',
  },
  {
    name: 'Monsieur CAMARET   L',
    role: 'Capitaine au Long Cours',
    bio: '',
  },
  {
    name: 'Christiane EZCUTARI',
    role: 'Présidente du Conseil Supérieur de la Marine Marchande',
    bio: 'Du 28 avril 1979 Au 24 avril 1982',
  },
  {
    name: 'Monsieur ESCARRAS   J-F',
    role: 'Capitaine de la Marine Marchande',
    bio: 'Du 4 juin 1982 Au 2 novembre 1982',
  },
  {
    name: 'Monsieur CHAUVIN   J',
    role: 'Capitaine au Long Cours',
    bio: 'Du 3 novembre 1982 Au 13 novembre 1983',
  },
  {
    name: 'Monsieur BETTON   M',
    role: 'Capitaine au Long Cours',
    bio: 'Du 14 novembre 1983 Au 6 février 1986',
  },
  {
    name: 'Monsieur SANTUCCI   H',
    role: 'Officier Radioélectricien de 1ère classe',
    bio: 'Du 7 février 1986 Au 26 mars 2002',
  },
  {
    name: 'Monsieur SORBA   C',
    role: 'Officier Mécanicien de 1ère classe',
    bio: 'Du 1er avril 2002 au 28 mai 2009',
  },  {
    name: 'Monsieur DEFEVER   D',
    role: 'Officier Mécanicien de 1ère Classe',
    bio: 'Du 28 mai 2009 au 3 mai 2018',
  },
  {
    name: 'Monsieur GUILLOU P',
    role: 'Capitaine de 1ère Classe de la Navigation Maritime',
    bio: 'Du 3 mai 2018 au 22 septembre 2020',
  },
  {
    name: 'Monsieur LE GUIGOT  D',
    role: 'Capitaine de Vaisseau',
    bio: 'Du 22 septembre 2020 au 27 mai 2021',
  },
  {
    name: 'Monsieur PIOGER Thierry',
    role: 'Architecte naval',
    bio: 'Du 27 mai 2021 au ?',
  },
]
</script>