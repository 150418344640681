<script>
import AppHeader from "@/components/Essentials/AppHeader.vue";
import AppFooter from "@/components/Essentials/AppFooter.vue";
import CongresView from "@/components/Congres/Vue/CongresView.vue";


export default {
  name: "CongresVue",
  components: {CongresView, AppFooter, AppHeader}
}
</script>

<template>
  <AppHeader></AppHeader>
  <CongresView></CongresView>
  <AppFooter></AppFooter>
</template>