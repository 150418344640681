<script>
import AppHeader from "@/components/Essentials/AppHeader.vue";
import AppFooter from "@/components/Essentials/AppFooter.vue";
import CongresList from "@/components/Congres/CongresList.vue";
import Banner from "@/components/Congres/Banner.vue";
import Fastnav from "@/components/Home/FastNav/Fastnav.vue";

export default {
  name: "Congres",
  components: {Fastnav, Banner, CongresList, AppFooter, AppHeader}
}
</script>

<template>
  <AppHeader></AppHeader>
  <Banner></Banner>
  <CongresList></CongresList>
  <Fastnav></Fastnav>
  <AppFooter></AppFooter>
</template>

<style scoped>

</style>