<template>
  <div class="mt-12 bg-[#111827] p-6 rounded-xl shadow border-gray-700 border">
    <div class="px-4 sm:px-0">
      <h3 class="text-base/7 font-semibold text-white">Nous contacter</h3>
      <p class="mt-1 max-w-2xl text-sm/6 text-gray-400">Nos informations pour nous contacter.</p>
    </div>
    <div class="mt-6 border-gray-100">
      <dl class="">
        <div class="rounded-xl px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
          <dt class="text-sm/6 font-medium text-gray-400">Nom</dt>
          <dd class="mt-1 text-sm/6 text-white sm:col-span-2 sm:mt-0">Hervé Duong</dd>
        </div>
        <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
          <dt class="text-sm/6 font-medium text-gray-400">Email</dt>
          <dd class="mt-1 text-sm/6 text-white sm:col-span-2 sm:mt-0">herve.duong@hbc-group.fr</dd>
        </div>
        <div class="rounded-xl px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
          <dt class="text-sm/6 font-medium text-gray-400">Nom</dt>
          <dd class="mt-1 text-sm/6 text-white sm:col-span-2 sm:mt-0">Bastien Rapaud</dd>
        </div>
        <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
          <dt class="text-sm/6 font-medium text-gray-400">Email</dt>
          <dd class="mt-1 text-sm/6 text-white sm:col-span-2 sm:mt-0">bastien.rapaud@hbc-group.fr</dd>
        </div>
        <div class="rounded-xl px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
          <dt class="text-sm/6 font-medium text-gray-400">Numéro de l'entreprise</dt>
          <dd class="mt-1 text-sm/6 text-white sm:col-span-2 sm:mt-0">07 56 88 91 05</dd>
        </div>
      </dl>
    </div>
  </div>


</template>

<script setup>
</script>