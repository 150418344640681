import { createRouter, createWebHistory } from 'vue-router'
import Index from '../views/Index.vue'
import SigninPage from "@/views/SigninPage.vue";
import ContactPage from "@/views/ContactPage.vue";
import SectionsPage from "@/views/SectionsPage.vue";
import DecoresPage from "@/views/DecoresPage.vue";
import SectionsVue from "@/views/SectionsVue.vue";
import HistoirePage from "@/views/HistoirePage.vue";
import HonorPage from "@/views/HonorPage.vue";
import StatusPage from "@/views/StatusPage.vue";
import OrdrePage from "@/views/OrdrePage.vue";
import DecoresMMPage from "@/views/DecoresMMPage.vue";
import CdmPage from "@/views/CdmPage.vue";
import Backoffice from "@/views/Backoffice/Backoffice.vue";
import Prix from "@/views/Prix.vue";
import PrixVue from "@/views/PrixVue.vue";
import Congres from "@/views/Congres.vue";
import CongresVue from "@/views/CongresVue.vue";
import Nouvelles from "@/views/Nouvelles.vue";
import NouvellesVue from "@/views/NouvellesVue.vue";

const routes = [
  {
    path: '/',
    name: 'index',
    component: Index
  },
  {
    path: '/page-de-connexion',
    name: 'signin',
    component: SigninPage
  },
  {
    path: '/support',
    name: 'contact',
    component: ContactPage
  },
  {
    path: '/sections',
    name: 'sections',
    component: SectionsPage
  },
  {
    path: '/sections/:id',
    name: 'sectionsvue',
    component: SectionsVue
  },
  {
    path: '/les-decores',
    name: 'decores',
    component: DecoresPage
  },
  {
    path: '/les-decores-mm',
    name: 'decoresmm',
    component: DecoresMMPage
  },
  {
    path: '/histoire-maritime',
    name: 'histoire',
    component: HistoirePage
  },
  {
    path: '/medaille-honneur',
    name: 'honor',
    component: HonorPage
  },
  {
    path: '/statuts-federation',
    name: 'status',
    component: StatusPage
  },
  {
    path: '/conseil-de-l-ordre',
    name: 'ordre',
    component: OrdrePage
  },
  {
    path: '/centre-de-documentation-maritime',
    name: 'cdm',
    component: CdmPage
  },
  {
    path: '/prix-litteraires',
    name: 'prix',
    component: Prix
  },
  {
    path: '/prix-litteraires/:id',
    name: 'prixvue',
    component: PrixVue
  },
  {
    path: '/congres-fnmm',
    name: 'congres',
    component: Congres
  },
  {
    path: '/congres-fnmm/:id',
    name: 'congresvue',
    component: CongresVue
  },
  {
    path: '/nouvelles-de-la-federation',
    name: 'nouvelles',
    component: Nouvelles
  },
  {
    path: '/nouvelles-de-la-federation/article/:id',
    name: 'nouvellesvue',
    component: NouvellesVue
  },
  {
    path: '/espace-membre',
    name: 'backoffice',
    component: Backoffice
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
