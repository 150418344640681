<template>
  <div class="bg-gray-900 rounded-xl shadow border-gray-700 border">
    <div class="mx-auto max-w-7xl">
      <div class="bg-gray-900 py-10 rounded-xl shadow">
        <div class="px-4 sm:px-6 lg:px-8">
          <div class="flow-root">
            <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <table class="min-w-full divide-y divide-gray-700">
                  <thead>
                  <tr>
                    <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-0">Nom</th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-white">Objet</th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-white">Email</th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-white">Etat</th>
                    <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-0">
                      <span class="sr-only">Edit</span>
                    </th>
                  </tr>
                  </thead>
                  <tbody class="divide-y divide-gray-800">
                  <tr v-for="person in people" :key="person.email">
                    <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-white sm:pl-0">{{ person.name }}</td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-300">{{ person.title }}</td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-300">{{ person.email }}</td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-300">{{ person.etat }}</td>
                    <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                      <a href="#" class="text-green-400 hover:text-green-300">Détails<span class="sr-only">, {{ person.name }}</span></a>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const people = [
  { name: 'Lindsay Walton', title: 'Front-end Developer', email: 'lindsay.walton@example.com', etat: 'non lu' },
  { name: 'James Smith', title: 'Back-end Developer', email: 'james.smith@example.com', etat: 'non lu' },
  { name: 'Sofia Brown', title: 'UX Designer', email: 'sofia.brown@example.com', etat: 'non lu' },
  { name: 'Michael Johnson', title: 'Product Manager', email: 'michael.johnson@example.com', etat: 'non lu' },
  { name: 'Emma Garcia', title: 'Marketing Specialist', email: 'emma.garcia@example.com', etat: 'non lu' },
  { name: 'Oliver Wilson', title: 'Full-stack Developer', email: 'oliver.wilson@example.com', etat: 'non lu' },
  { name: 'Ava Martinez', title: 'DevOps Engineer', email: 'ava.martinez@example.com', etat: 'non lu' },
  { name: 'Elijah Davis', title: 'QA Analyst', email: 'elijah.davis@example.com', etat: 'non lu' },
  { name: 'Mia Hernandez', title: 'Data Scientist', email: 'mia.hernandez@example.com', etat: 'non lu' },
  { name: 'William Lopez', title: 'Project Coordinator', email: 'william.lopez@example.com', etat: 'non lu' },
  { name: 'Isabella Walker', title: 'Business Analyst', email: 'isabella.walker@example.com', etat: 'non lu' },
  { name: 'Lucas Hill', title: 'Cybersecurity Specialist', email: 'lucas.hill@example.com', etat: 'non lu' },
  { name: 'Charlotte Moore', title: 'Content Writer', email: 'charlotte.moore@example.com', etat: 'non lu' },
  { name: 'Amelia Thomas', title: 'Graphic Designer', email: 'amelia.thomas@example.com', etat: 'non lu' },
  { name: 'Ethan Lee', title: 'Cloud Architect', email: 'ethan.lee@example.com', etat: 'non lu' },
  { name: 'Harper White', title: 'SEO Specialist', email: 'harper.white@example.com', etat: 'non lu' },
];
</script>