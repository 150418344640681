<template>
  <div class="py-28 w-full bg-[#072653] min-h-[450px] mt-[136px] lg:mt-[160px]">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto max-w-2xl lg:mx-0">
        <h2 class="text-pretty text-4xl font-semibold tracking-tight text-white sm:text-5xl">BUREAU NATIONAL</h2>
        <p class="mt-6 text-lg/8 text-gray-200">Découvrez les membres du Bureau National, leurs rôles au sein de notre organisation, ainsi que leurs contributions essentielles à la réalisation de nos objectifs et missions.</p>
        <a href="/" class="text-gray-200 hover:text-violet-600 flex px-4 py-2 rounded-xl w-52 justify-center items-center gap-2 mt-6 border ">
          Accéder au site
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-square-arrow-out-up-right"><path d="M21 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h6"/><path d="m21 3-9 9"/><path d="M15 3h6v6"/></svg>
        </a>
      </div>
    </div>
  </div>
  <div class="bg-white">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <ul role="list" class="mx-auto my-20 grid max-w-2xl grid-cols-2 gap-x-8 gap-y-16 text-center sm:grid-cols-3 md:grid-cols-4 lg:mx-0 lg:max-w-none lg:grid-cols-4 xl:grid-cols-5">
        <li v-for="person in people" :key="person.name">
          <img class="mx-auto size-32 object-cover rounded-full" :src="person.imageUrl" alt="" />
          <h3 class="mt-6 text-base/7 font-semibold tracking-tight text-gray-900">{{ person.name }}</h3>
          <p class="text-sm/6 text-gray-600">{{ person.role }}</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
const people = [
  {
    name: 'VIOLA Fabrice',
    role: 'Président National',
    imageUrl:
        'https://api.meritemaritime-fnmm.com/uploads/1730970743835.jpg',
  },
  {
    name: 'PIOGER Thierry',
    role: 'Vice-Président',
    imageUrl:
        'https://api.meritemaritime-fnmm.com/uploads/1725618682675.jpg',
  },
  {
    name: 'EMERIAU Patrick',
    role: 'Secrétaire général',
    imageUrl:
        'https://api.meritemaritime-fnmm.com/uploads/1725618726380.jpg',
  },
  {
    name: 'PLUMET Stéphane',
    role: 'Trésorier général',
    imageUrl:
        'https://api.meritemaritime-fnmm.com/uploads/1725618762793.jpg',
  },
  {
    name: 'BELAUD Anne',
    role: 'Trésorière adjointe',
    imageUrl:
        'https://api.meritemaritime-fnmm.com/uploads/1725618797682.jpg',
  },
  {
    name: 'DERANSI Laëtitia',
    role: 'Déléguée armateurs',
    imageUrl:
        'https://api.meritemaritime-fnmm.com/uploads/1727770493167.jpg',
  },
  {
    name: 'RUZ Joël',
    role: 'Délégué adhérents',
    imageUrl:
        'https://api.meritemaritime-fnmm.com/uploads/1726993629397.jpg',
  },
  {
    name: 'FAUVEL Christian',
    role: 'Chargé de communication et délégué nautisme',
    imageUrl:
        'https://api.meritemaritime-fnmm.com/uploads/1725618872955.jpg',
  },
]
</script>