<template>
  <div class="bg-neutral-50 py-24 sm:py-32">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <h2 class="max-w-2xl mb-20 text-balance text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">Actualités</h2>

      <div v-if="posts.length" class="mx-auto mb-16 max-w-2xl lg:mx-0 lg:max-w-none lg:grid lg:grid-cols-1">
        <a :href="posts[0].href" class="flex flex-col items-start justify-between">
          <div class="relative w-full">
            <img :src="posts[0].imageUrl" alt="" class="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]" />
            <div class="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
          </div>
          <div class="max-w-xl mt-8">
            <div class="flex items-center gap-x-4 text-xs">
              <time :datetime="posts[0].datetime" class="text-gray-500">{{ posts[0].date }}</time>
            </div>
            <div class="group relative">
              <h3 class="mt-3 text-2xl font-semibold text-gray-900 group-hover:text-gray-600">
                <span>
                  <span class="absolute inset-0" />
                  {{ posts[0].title }}
                </span>
              </h3>
              <p class="mt-5 text-base text-gray-600">{{ posts[0].description }}</p>
            </div>
          </div>
        </a>
      </div>

      <div class="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
        <a v-for="post in posts.slice(1, 10)" :key="post.id" :href="post.href" class="flex flex-col items-start justify-between">
          <div class="relative w-full">
            <img :src="post.imageUrl" alt="" class="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]" />
            <div class="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
          </div>
          <div class="max-w-xl">
            <div class="mt-8 flex items-center gap-x-4 text-xs">
              <time :datetime="post.datetime" class="text-gray-500">{{ post.date }}</time>
            </div>
            <div class="group relative">
              <h3 class="mt-3 text-lg font-semibold text-gray-900 group-hover:text-gray-600">
                <span>
                  <span class="absolute inset-0" />
                  {{ post.title }}
                </span>
              </h3>
              <p class="mt-5 line-clamp-3 text-sm text-gray-600">{{ post.description }}</p>
            </div>
          </div>
        </a>
      </div>

    </div>
  </div>
</template>

<script setup>
const posts = [
  {
    id: 1,
    title: 'Boost your conversion rate',
    href: '/nouvelles-de-la-federation/article/1',
    description:
        'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
    imageUrl:
        'https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3603&q=80',
    date: 'Mar 16, 2020',
    datetime: '2020-03-16',
  },
  {
    id: 2,
    title: 'How to use search engine',
    href: '/',
    description:
        'Optio cumque nihil impedit distinctio. Quae consequatur architecto voluptate facilis libero vel fugiat voluptate assumenda.',
    imageUrl:
        'https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3603&q=80',
    date: 'Apr 10, 2020',
    datetime: '2020-04-10',
  },
  {
    id: 3,
    title: 'Improve your customer experience',
    href: '/',
    description:
        'Magnam similique facere. Error aperiam voluptates omnis facere quaerat reprehenderit rerum dolorum. Deserunt voluptate est quia occaecati voluptatem.',
    imageUrl:
        'https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3603&q=80',
    date: 'May 8, 2020',
    datetime: '2020-05-08',
  },
  {
    id: 4,
    title: 'How to improve your retention rates',
    href: '/',
    description:
        'Et enim voluptate laborum voluptatem id. Consequatur suscipit nihil assumenda odio tempora. Et id soluta et molestiae molestiae.',
    imageUrl:
        'https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3603&q=80',
    date: 'Jun 1, 2020',
    datetime: '2020-06-01',
  } ,
  {
    id: 5,
    title: 'Improve your customer experience',
    href: '/',
    description:
        'Magnam similique facere. Error aperiam voluptates omnis facere quaerat reprehenderit rerum dolorum. Deserunt voluptate est quia occaecati voluptatem.',
    imageUrl:
        'https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3603&q=80',
    date: 'May 8, 2020',
    datetime: '2020-05-08',
  },
  {
    id: 6,
    title: 'How to improve your retention rates',
    href: '/',
    description:
        'Et enim voluptate laborum voluptatem id. Consequatur suscipit nihil assumenda odio tempora. Et id soluta et molestiae molestiae.',
    imageUrl:
        'https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3603&q=80',
    date: 'Jun 1, 2020',
    datetime: '2020-06-01',
  }
  ,
  {
    id: 7,
    title: 'Improve your customer experience',
    href: '/',
    description:
        'Magnam similique facere. Error aperiam voluptates omnis facere quaerat reprehenderit rerum dolorum. Deserunt voluptate est quia occaecati voluptatem.',
    imageUrl:
        'https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3603&q=80',
    date: 'May 8, 2020',
    datetime: '2020-05-08',
  },
  {
    id: 8,
    title: 'How to improve your retention rates',
    href: '/',
    description:
        'Et enim voluptate laborum voluptatem id. Consequatur suscipit nihil assumenda odio tempora. Et id soluta et molestiae molestiae.',
    imageUrl:
        'https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3603&q=80',
    date: 'Jun 1, 2020',
    datetime: '2020-06-01',
  }
  ,
  {
    id: 9,
    title: 'Improve your customer experience',
    href: '/',
    description:
        'Magnam similique facere. Error aperiam voluptates omnis facere quaerat reprehenderit rerum dolorum. Deserunt voluptate est quia occaecati voluptatem.',
    imageUrl:
        'https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3603&q=80',
    date: 'May 8, 2020',
    datetime: '2020-05-08',
  },
  {
    id: 10,
    title: 'How to improve your retention rates',
    href: '/',
    description:
        'Et enim voluptate laborum voluptatem id. Consequatur suscipit nihil assumenda odio tempora. Et id soluta et molestiae molestiae.',
    imageUrl:
        'https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3603&q=80',
    date: 'Jun 1, 2020',
    datetime: '2020-06-01',
  }
]
</script>
