<script>
  import AppHeader from "@/components/Essentials/AppHeader.vue";
  import AppFooter from "@/components/Essentials/AppFooter.vue";
  import Sections from "@/components/Sections/Sections.vue";
  import Fastnav from "@/components/Home/FastNav/Fastnav.vue";

  export default {
    name : 'SectionsPages',
    components : {
      Fastnav,
      Sections,
      AppFooter,
      AppHeader

    }
  }
</script>

<template>
  <AppHeader></AppHeader>
  <Sections></Sections>
  <fastnav></fastnav>
  <AppFooter></AppFooter>
</template>
