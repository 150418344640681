<script>
import AppHeader from "@/components/Essentials/AppHeader.vue";
import AppFooter from "@/components/Essentials/AppFooter.vue";
import Banner from "@/components/Nouvelles/Banner.vue";
import NouvelleView from "@/components/Nouvelles/Vue/NouvelleView.vue";

export default {
  name: "NouvellesVue",
  components: {NouvelleView, Banner, AppFooter, AppHeader}
}
</script>

<template>
  <AppHeader></AppHeader>
  <Banner></Banner>
  <NouvelleView></NouvelleView>
  <AppFooter></AppFooter>
</template>
