<template>
  <div class="bg-white py-24 md:py-32 lg:py-40">
    <div class="mx-auto grid max-w-7xl grid-cols-1 gap-20 px-6 lg:px-8 xl:grid-cols-3">
      <div class="mx-auto max-w-2xl lg:mx-0">
        <h2 class="text-pretty text-4xl font-semibold tracking-tight text-gray-900 sm:text-4xl">Présidents</h2>
      </div>
      <ul role="list" class="mx-auto grid max-w-2xl grid-cols-1 gap-x-6 gap-y-20 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:gap-x-8 xl:col-span-2">
        <li v-for="president in president" :key="president.name">
          <h3 class="mt-6 text-lg/8 font-semibold text-gray-900">{{ president.name }}</h3>
          <p class="text-base/7 text-gray-600">{{ president.role }}</p>
          <p class="mt-4 text-base/7 text-gray-600">{{ president.bio }}</p>
        </li>
      </ul>
    </div>
  </div>
  <div class="bg-neutral-50 pt-16 pb-24 md:pb-32 lg:pb-40">
    <div class="mx-auto grid max-w-7xl grid-cols-1 gap-20 px-6 lg:px-8 xl:grid-cols-3">
      <div class="mx-auto max-w-2xl lg:mx-0">
        <h2 class="text-pretty text-4xl font-semibold tracking-tight text-gray-900 sm:text-4xl">Membres</h2>
      </div>
      <ul role="list" class="mx-auto grid max-w-2xl grid-cols-1 gap-x-6 gap-y-20 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:gap-x-8 xl:col-span-2">
        <li v-for="person in people" :key="person.name">
          <h3 class="mt-6 text-lg/8 font-semibold text-gray-900">{{ person.name }}</h3>
          <p class="text-base/7 text-gray-600">{{ person.role }}</p>
          <p class="mt-4 text-base/7 text-gray-600">{{ person.bio }}</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
const president = [
  {
    name: 'Hervé BERVILLE',
    role: 'Président',
    bio: 'Secrétaire d\'Etat à la Mer auprès du Premier Ministre chargé de la Mer.',
  },
  {
    name: 'L\'Amiral (2S) Bernard-Antoine MORIO DE L\'ISLE',
    role: 'Vice-Président',
    bio: 'Membre du Conseil de l\'Ordre de la Légion d\'Honneur.',
  },
]

const people = [
  {
    name: 'Terry OLSON',
    role: 'Conseiller d\'Etat',
    bio: '',
  },
  {
    name: 'Contre-amiral (2S) Jean-Michel MARTINET',
    role: 'Officier Général de la Marine Nationale',
    bio: '',
  },
  {
    name: 'Guillaume SELLIER',
    role: 'Administrateur Général hors classe des Affaires Maritimes',
    bio: '',
  },
  {
    name: 'Eric BANEL',
    role: 'Directeur Général des Affaires Maritimes de la pêche et de l\'aquaculture',
    bio: '',
  },
  {
    name: 'Christiane EZCUTARI',
    role: 'Présidente du Conseil Supérieur de la Marine Marchande',
    bio: '',
  },
  {
    name: 'Jean-François JOUFFRAY',
    role: 'Président du Conseil Supérieur des Gens de Mer',
    bio: '',
  },
  {
    name: 'Sophie PANONACLE',
    role: 'Présidente du Bureau du Conseil National de la Mer et des Littoraux.',
    bio: '',
  },
  {
    name: 'Olivier LE NEZET',
    role: 'Président du Comité National des Pêches Maritimes et Elevages Marins.',
    bio: '',
  },
  {
    name: 'Marie-Luce PENCHARD',
    role: 'Personnalité ultramarine qualifiée.',
    bio: '',
  },
]
</script>