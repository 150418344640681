<template>
  <div class="flex min-h-full flex-1 flex-col justify-center py-24 sm:px-6 lg:px-8 mt-[136px] lg:mt-[160px]">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <img class="mx-auto h-16 w-auto" src="@/assets/Logos/logo.png"/>
      <h2 class="mt-6 text-center text-2xl/9 font-bold tracking-tight text-gray-900">Connectez-vous à votre espace</h2>
    </div>

    <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
      <div class="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
        <form class="space-y-6" action="#" method="POST">
          <div>
            <label for="email" class="block text-sm/6 font-medium text-gray-900">Identifiant</label>
            <div class="mt-2">
              <input id="username" name="username" type="text" autocomplete="text" required="" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6" />
            </div>
          </div>

          <div>
            <label for="password" class="block text-sm/6 font-medium text-gray-900">Mot de passe</label>
            <div class="mt-2">
              <input id="password" name="password" type="password" autocomplete="current-password" required="" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6" />
            </div>
          </div>

          <div class="flex gap-2 flex-col">
            <div class="flex items-center">
              <input id="remember-me" name="remember-me" type="checkbox" class="size-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
              <label for="remember-me" class="ml-3 block text-sm/6 text-gray-900">Se souvenir de moi</label>
            </div>
            <small>Votre session sera enregistrée pendant 15 jours. Passé ce délai, une nouvelle demande de connexion sera requise.</small>
          </div>

          <div>
            <button type="submit" class="flex w-full justify-center rounded-md bg-[#072653] px-3 py-1.5 text-sm/6 font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Se connecter</button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="inset-x-0 bottom-0 flex flex-col justify-between gap-x-8 gap-y-4 bg-white p-6 ring-1 ring-gray-900/10 md:flex-row md:items-center lg:px-8">
    <p class="max-w-4xl text-sm/6 text-gray-900">En cas d'incident de connexion, d'oubli de mot de passe, de nom d'utilisateur, ou pour toute autre assistance, veuillez contacter notre support technique.</p>
    <div class="flex flex-none items-center gap-x-5">
      <a href="/support" type="button" class="rounded-md bg-gray-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900">Contacter le support</a>
    </div>
  </div>
</template>
