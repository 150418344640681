<script setup>
import { ref, computed  } from 'vue'
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue'
import { ChevronUpDownIcon } from '@heroicons/vue/16/solid'
import { CheckIcon, TrashIcon } from '@heroicons/vue/20/solid'

const data = {
  sections: [
    {
      id: '1',
      title: 'Bureau National',
      description: '',
      url: '',
    },
    {
      id: '2',
      title: 'ALPES MARITIMES',
      description: '',
      url: '',
    },
    {
      id: '3',
      title: 'BOUCHES DU RHONE',
      description: '',
      url: '',
    },
    {
      id: '4',
      title: 'BRETAGNE SUD et VENDEE',
      description: '',
      url: '',
    },
    {
      id: '5',
      title: 'CALVADOS',
      description: '',
      url: '',
    },
    {
      id: '6',
      title: "COTES D'ARMOR",
      description: '',
      url: '',
    },
    {
      id: '7',
      title: 'CORSE',
      description: '',
      url: '',
    },
    {
      id: '8',
      title: 'FINISTERE',
      description: '',
      url: '',
    },
    {
      id: '9',
      title: 'GIRONDE',
      description: '',
      url: '',
    },
    {
      id: '10',
      title: 'GUADELOUPE',
      description: '',
      url: '',
    },
    {
      id: '11',
      title: 'ILE-DE-FRANCE',
      description: '',
      url: '',
    },
    {
      id: '12',
      title: 'ILLE-ET-VILAINE',
      description: '',
      url: '',
    },
    {
      id: '13',
      title: 'MANCHE',
      description: '',
      url: '',
    },
    {
      id: '14',
      title: 'MARTINIQUE',
      description: '',
      url: '',
    },
    {
      id: '15',
      title: 'MORBIHAN',
      description: '',
      url: '',
    },
    {
      id: '16',
      title: 'NORD-PAS-DE-CALAIS',
      description: '',
      url: '',
    },
    {
      id: '17',
      title: 'OCCITANIE',
      description: '',
      url: '',
    },
    {
      id: '18',
      title: 'POITOU-CHARENTES',
      description: '',
      url: '',
    },
    {
      id: '19',
      title: 'PYRENEES-ATLANTIQUE',
      description: '',
      url: '',
    },
    {
      id: '20',
      title: 'SOI-REUNION',
      description: '',
      url: '',
    },
    {
      id: '21',
      title: 'SEINE-MARITIME',
      description: '',
      url: '',
    },
    {
      id: '22',
      title: 'VAR',
      description: '',
      url: '',
    },
  ],
  members: [
    {
      idSection: '1',
      idMember: '1',
      name: 'VIOLA Fabrice',
      role: 'Président National',
      img: 'https://api.meritemaritime-fnmm.com/uploads/1730970743835.jpg',
    },
    {
      idSection: '1',
      idMember: '2',
      name: 'PIOGER Thierry',
      role: 'Vice-Président',
      img: 'https://api.meritemaritime-fnmm.com/uploads/1725618682675.jpg',
    },
    {
      idSection: '1',
      idMember: '3',
      name: 'EMERIAU Patrick',
      role: 'Secrétaire général',
      img: 'https://api.meritemaritime-fnmm.com/uploads/1725618726380.jpg',
    },
    {
      idSection: '1',
      idMember: '4',
      name: 'PLUMET Stéphane',
      role: 'Trésorier général',
      img: 'https://api.meritemaritime-fnmm.com/uploads/1725618762793.jpg',
    },
    {
      idSection: '1',
      idMember: '5',
      name: 'BELAUD Anne',
      role: 'Trésorière adjointe',
      img: 'https://api.meritemaritime-fnmm.com/uploads/1725618797682.jpg',
    },
    {
      idSection: '1',
      idMember: '6',
      name: 'DERANSI Laëtitia',
      role: 'Déléguée armateurs',
      img: 'https://api.meritemaritime-fnmm.com/uploads/1727770493167.jpg',
    },
    {
      idSection: '1',
      idMember: '7',
      name: 'RUZ Joël',
      role: 'Délégué adhérents',
      img: 'https://api.meritemaritime-fnmm.com/uploads/1726993629397.jpg',
    },
    {
      idSection: '1',
      idMember: '8',
      name: 'FAUVEL Christian',
      role: 'Chargé de communication et délégué nautisme',
      img: 'https://api.meritemaritime-fnmm.com/uploads/1725618872955.jpg',
    },

    {
      idSection: '2',
      idMember: '9',
      name: 'PETITJEAN Eric',
      role: 'Président',
      img: 'https://static.wixstatic.com/media/80c489_5fed392345ab48ee9cecc1a2183845a0~mv2.png/v1/fill/w_97,h_128,al_c,lg_1,q_85,usm_1.20_1.00_0.01,enc_auto/Capture_d_%C3%A9cran__320_-removebg-preview.png',
    },
    {
      idSection: '2',
      idMember: '10',
      name: 'LECOURT Loïc',
      role: 'Secrétaire',
      img: '',
    },
    {
      idSection: '2',
      idMember: '11',
      name: 'ROGER Jean-Yves',
      role: 'Trésorier',
      img: 'https://static.wixstatic.com/media/80c489_3fc0c107a4ad467aa2f00ba1bda29039~mv2.png/v1/fill/w_102,h_130,al_c,q_85,usm_1.20_1.00_0.01,enc_auto/Capture%20d%E2%80%99%C3%A9cran%20(314).png',
    },
    {
      idSection: '2',
      idMember: '12',
      name: 'ROSSIGNOL Thierry',
      role: 'Président honoraire',
      img: '',
    },
  ],
}

const selectedSection = ref(data.sections[0])

// Computed property pour filtrer les membres par section sélectionnée
const filteredMembers = computed(() => {
  return data.members.filter(member => member.idSection === selectedSection.value.id)
})

const selectedMember = ref(null)
</script>


<template>
  <div class="bg-gray-900 p-6 rounded-xl shadow mt-20 border-gray-700 border">
    <!-- Liste des sections -->
    <Listbox as="div" v-model="selectedSection">
      <ListboxLabel class="block text-sm/6 font-medium text-gray-200">Sélectionner une section</ListboxLabel>
      <div class="relative mt-2">
        <ListboxButton class="grid w-full cursor-default grid-cols-1 rounded-md bg-gray-700 py-4 pl-3 pr-2 text-left text-gray-200 outline outline-1 -outline-offset-1 outline-gray-700 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6">
          <span class="col-start-1 row-start-1 truncate pr-6">{{ selectedSection.title }}</span>
          <ChevronUpDownIcon class="col-start-1 row-start-1 size-5 self-center justify-self-end text-gray-500 sm:size-4" aria-hidden="true" />
        </ListboxButton>

        <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
          <ListboxOptions class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-gray-700 py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
            <ListboxOption as="template" v-for="section in data.sections" :key="section.id" :value="section" v-slot="{ active, selected }">
              <li :class="[active ? 'bg-green-600 text-white outline-none ' : 'text-gray-200', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">{{ section.title }}</span>
                <span v-if="selected" :class="[active ? 'text-white' : 'text-green-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                  <CheckIcon class="size-5" aria-hidden="true" />
                </span>
              </li>
            </ListboxOption>
          </ListboxOptions>
        </transition>
      </div>
    </Listbox>
    <button type="submit" class="rounded-md mt-6 flex w-full justify-center bg-green-600 px-3 py-4 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600">Créer</button>
  </div>


<!--  Affiche la div soit quand on clique sur créer ou soit quand on sélect une section-->
  <div class="bg-gray-900 p-6 rounded-xl shadow mt-6 border-gray-700 border">
    <div class="flex gap-6 flex-col">
      <div>
        <label for="name" class="text-gray-200">Nom section</label>
        <input type="text" name="name" id="name" placeholder="-" class="mt-2 px-4 py-4 block w-full rounded-md border-0 bg-white/5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm/6" />
      </div>
      <div>
        <label for="name" class="text-gray-200">Description</label>
        <textarea name="name" id="name" placeholder="-" class="mt-2 px-4 py-4 block w-full rounded-md border-0 bg-white/5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm/6" />
      </div>
      <div>
        <label for="url" class="text-gray-200">Lien du site</label>
        <input type="text" name="url" id="url" placeholder="-" class="mt-2 px-4 py-4 block w-full rounded-md border-0 bg-white/5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm/6" />
      </div>
    </div>

    <div class="mt-6 flex items-center justify-end gap-x-4">
      <button type="submit" class="rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600">Enregistrer</button>
      <button type="submit" class="rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600">
        <TrashIcon class="w-5 h-5 shrink-0 text-gray-200 hover:text-red-400" aria-hidden="true"/>
      </button>
    </div>
  </div>


  <div class="bg-gray-900 p-6 rounded-xl shadow mt-20 border-gray-700 border">
    <Listbox as="div" v-model="selectedMember">
      <ListboxLabel class="block text-sm/6 font-medium text-gray-200">Sélectionner un membre</ListboxLabel>
      <div class="relative mt-2">
        <ListboxButton class="grid w-full cursor-default grid-cols-1 rounded-md bg-gray-700 py-2 pl-3 pr-2 text-left text-gray-200 outline outline-1 -outline-offset-1 outline-gray-700 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6">
          <span class="col-start-1 row-start-1 flex items-center gap-3 pr-6">
            <img v-if="selectedMember?.img" :src="selectedMember.img" alt="" class="size-10 shrink-0 rounded-full object-cover" />
            <span class="block truncate">{{ selectedMember?.name }}</span>
          </span>
          <ChevronUpDownIcon class="col-start-1 row-start-1 size-5 self-center justify-self-end text-gray-500 sm:size-4" aria-hidden="true" />
        </ListboxButton>

        <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
          <ListboxOptions class="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-gray-700 py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
            <ListboxOption as="template" v-for="member in filteredMembers" :key="member.idMember" :value="member" v-slot="{ active, selected }">
              <li :class="[active ? 'bg-green-600 text-white outline-none' : 'text-gray-200', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                <div class="flex items-center">
                  <img v-if="member.img" :src="member.img" alt="" class="size-10 shrink-0 rounded-full object-cover" />
                  <span :class="[selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate']">{{ member.name }}</span>
                </div>
                <span v-if="selected" :class="[active ? 'text-white' : 'text-green-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                  <CheckIcon class="size-5" aria-hidden="true" />
                </span>
              </li>
            </ListboxOption>
          </ListboxOptions>
        </transition>
      </div>
    </Listbox>
    <button type="submit" class="rounded-md mt-6 flex w-full justify-center bg-green-600 px-3 py-4 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600">Créer</button>
  </div>


  <!--  Affiche la div soit quand on clique sur créer ou soit quand on sélect un membre-->
  <div class="bg-gray-900 p-6 rounded-xl shadow mt-6 border-gray-700 border">
    <div class="flex gap-6 flex-col">
      <div>
        <label for="name" class="text-gray-200">Nom complet</label>
        <input type="text" name="name" id="name" placeholder="-" class="mt-2 px-4 py-4 block w-full rounded-md border-0 bg-white/5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm/6" />
      </div>
      <div>
        <label for="role" class="text-gray-200">Role</label>
        <input type="text" name="role" id="role" placeholder="-" class="mt-2 px-4 py-4 block w-full rounded-md border-0 bg-white/5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm/6" />
      </div>
      <div>
        <label for="url" class="text-gray-200">URL image</label>
        <input type="text" name="url" id="url" placeholder="-" class="mt-2 px-4 py-4 block w-full rounded-md border-0 bg-white/5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm/6" />
      </div>
    </div>

    <div class="mt-6 flex items-center justify-end gap-x-4">
      <button type="submit" class="rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600">Enregistrer</button>
      <button type="submit" class="rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600">
        <TrashIcon class="w-5 h-5 shrink-0 text-gray-200 hover:text-red-400" aria-hidden="true"/>
      </button>
    </div>
  </div>
</template>