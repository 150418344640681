<script setup>
import {PaperClipIcon, TrashIcon} from "@heroicons/vue/20/solid";

const link = [
  {
    title : 'https://www.meritemaritime-fnmm.com/exemple-de-lien',
    href : 'https://www.meritemaritime-fnmm.com/exemple-de-lien',
  },
  {
    title : 'https://www.meritemaritime-fnmm.com/exemple-de-lien',
    href : 'https://www.meritemaritime-fnmm.com/exemple-de-lien',
  },
  {
    title : 'https://www.meritemaritime-fnmm.com/exemple-de-lien',
    href : 'https://www.meritemaritime-fnmm.com/exemple-de-lien',
  },
  {
    title : 'https://www.meritemaritime-fnmm.com/exemple-de-lien',
    href : 'https://www.meritemaritime-fnmm.com/exemple-de-lien',
  },
]
</script>

<template>
  <h2 class="text-2xl font-semibold text-gray-200">Carrousel</h2>
  <p class="mt-1 text-sm/6 text-gray-300">Importez vos images pour les intégrer facilement à votre carrousel sur la page d'accueil.</p>
  <div class=" border-gray-700 border bg-gray-900 p-6 shadow rounded-xl mt-6">
    <div class="col-span-full">
      <label for="title" class="block text-sm/6 font-medium text-white">URL Image</label>
      <div class="mt-2">
        <input type="text" name="title" id="title" placeholder="-" class="mt-2 px-4 py-4 block w-full rounded-md border-0 bg-white/5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm/6" />
      </div>
      <div class="w-full flex justify-end mt-4">
        <button type="submit" class="rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600">Ajouter</button>
      </div>
    </div>
    <div class="col-span-full mt-6">
      <label for="title" class="block text-sm/6 font-medium text-white">Délai de défilement</label>
      <div class="mt-2">
        <input type="number" name="title" id="title" min="0" value="3000" placeholder="3000 = 3s" class="mt-2 px-4 py-4 block w-full rounded-md border-0 bg-white/5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm/6" />
      </div>
      <div class="w-full flex justify-end mt-4">
        <button type="submit" class="rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600">Enregistrer</button>
      </div>
    </div>
  </div>
  <div class="mt-16 border-t border-white/10 pt-28">
    <dl class="divide-y divide-white/10">
      <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
        <dt class="text-sm/6 font-medium text-white">Tout les liens</dt>
        <dd class="mt-2 text-sm text-white sm:col-span-2 sm:mt-0">
          <ul role="list" class="divide-y divide-white/10 rounded-md border border-white/20">
            <li v-for="link in link" :key="link.title" class="flex items-center justify-between py-4 pl-4 pr-5 text-sm/6">
              <div class="flex w-0 flex-1 items-center">
                <PaperClipIcon class="size-5 shrink-0 text-gray-400" aria-hidden="true" />
                <div class="ml-4 flex min-w-0 flex-1 gap-2">
                  <span class="truncate font-medium">{{ link.title }}</span>
                </div>
              </div>
              <div class="ml-4 shrink-0 items-center flex gap-4">
                <a :href="link.href" class="font-medium text-green-400 hover:text-green-300">Accéder</a>
                <button><TrashIcon class="w-5 h-5 shrink-0 text-gray-400 hover:text-red-400" aria-hidden="true" /></button>
              </div>
            </li>
          </ul>
        </dd>
      </div>
    </dl>
  </div>
  <h1 class="text-gray-200 text-xl">Visualiser</h1>
  <p class="text-gray-300">Rendu en temps réel sur la page vitrine.</p>
</template>