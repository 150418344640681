<script setup>
import { ref } from "vue";
import ListArticles from "@/components/Backoffice/GestionDesArticles/FNMM/components/ListArticles.vue";
import Stats from "@/components/Backoffice/GestionDesArticles/FNMM/components/Stats.vue";
import ViewArticle from "@/components/Backoffice/GestionDesArticles/FNMM/components/ViewArticle.vue";

const showListArticles = ref(true);

const switchToViewArticle = () => {
  showListArticles.value = false;
};

const switchToListArticles = () => {
  showListArticles.value = true;
};
</script>

<template>
  <div class="mb-6">
    <h2 class="text-2xl font-semibold text-gray-200">Gestion des articles</h2>
    <p class="mt-1 text-sm/6 text-gray-300">Gérez et publiez vos articles facilement depuis le back-office.</p>
  </div>
  <Stats></Stats>
  <ListArticles v-if="showListArticles" @view-article="switchToViewArticle"></ListArticles>
  <ViewArticle v-else @cancel="switchToListArticles"></ViewArticle>
</template>
