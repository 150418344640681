<template>
  <div class="bg-neutral-50 py-24">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <h2 class="max-w-2xl text-balance text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">Navigation rapide</h2>
      <div class="mt-20 flow-root">
        <div class="isolate -mt-16 grid max-w-sm grid-cols-1 gap-y-16 divide-y divide-gray-100 sm:mx-auto lg:-mx-8 lg:mt-0 lg:max-w-none lg:grid-cols-3 lg:divide-x lg:divide-y-0 xl:-mx-4">
          <div v-for="tier in tiers" :key="tier.id" class="pt-16 lg:px-8 lg:pt-0 xl:px-8">
            <h3 :id="tier.id" class="text-3xl min-h-[72px] font-semibold text-gray-900">{{ tier.name }}</h3>
            <p class="mt-4 lg:mt-10 sm:mt-0 text-sm/6 font-semibold text-gray-900 min-h-[120px]">{{ tier.description }}</p>
            <a :href="tier.href" :aria-describedby="tier.id" class="mt-10 block rounded-md bg-[#072653] px-3 py-2 text-center text-sm/6 font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
              Consulter
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>

const tiers = [
  {
    name: 'Annuaire FNMM-MH',
    id: 'annuaire',
    href: 'https://annuaire.fnmm.fr/',
    description: 'Découvrez les annuaire FNMM-MH, espace réservé.',
  },
  {
    name: 'Histoire Mérite Maritime',
    id: 'histoire',
    href: '/histoire-maritime',
    description: 'Plongez dans l\'histoire captivante et riche de la Fédération du Mérite Maritime, et découvrez les moments clés.',
  },
  {
    name: 'Les décorés Mérite Maritime',
    id: 'decores',
    href: '/les-decores-mm',
    description: 'Rechercher une personne ayant été décorés ou médaillés de 1930 à 2021.',
  },
  {
    name: 'Les décorés Médaillés d\'Honneur',
    id: 'decores',
    href: '/les-decores',
    description: 'Rechercher une personne ayant été décorés ou médaillés de 1930 à 2021.',
  },
  {
    name: 'Sections',
    id: 'sections',
    href: '/sections',
    description: 'Découvrez les membres de chaque section de notre fédération nationale du mérite maritime.',
  },
]
</script>